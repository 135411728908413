export const obrigatorio = (value, allValues, props, mensagem = "Campo obrigatório *") =>
  value ? undefined : mensagem;
export const obrigatorioComTrim = (value, allValues, props, mensagem = "Campo obrigatório *") =>
  value && value.trim() ? undefined : mensagem;
export const emailValido = (value, allValues, props, mensagem = "Endereço de email inválido") =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? mensagem : undefined;

export const ERRORS = {
  REQUIRED: "Campo obrigatório *",
  NEGATIVE: "Valor deve ser maior ou igual a zero *",
  NON_POSITIVE: "Valor deve ser maior que zero",
  TOO_LOW: "Valor abaixo do permitido",
  TOO_HIGH: "Valor acima do permitido",
};

export const nonNegative = (value, intl) => {
  if (value < 0) return intl.formatMessage({ defaultMessage: "Valor deve ser maior ou igual a zero *" });
  return undefined;
};

export const required = (value, intl) => {
  if (!value && value !== 0) return intl.formatMessage({ defaultMessage: "Campo obrigatório *" });
  return undefined;
};

export const positive = (value, intl) => {
  if (value <= 0)
    if (intl) return intl.formatMessage({ defaultMessage: "Valor deve ser maior que zero" });
    else return ERRORS.NON_POSITIVE;
  return undefined;
};

export const betweenRange = (floor, ceil) => value => {
  if (value < floor) return ERRORS.TOO_LOW;
  if (value > ceil) return ERRORS.TOO_HIGH;
  return undefined;
};
