/* eslint-disable vars-on-top */
/* eslint-disable prettier/prettier */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable no-undef */
/* eslint-disable no-var */
export function addUt() {
  var currentUrl = window.location.pathname;
  var host = window.location.href;
  var utDebug = host.includes("localhost") || host.includes("file://");
  currentUrl = currentUrl.replace(window.location.origin, "");

  if (utDebug) {
    console.log("UT EM MODO DEBUG");
    console.log("utEcId: " + utEcId);
    console.log("utUserId: " + utUserId);
    console.log("utEnvId: " + utEnvId);
    console.log("utSpotterId: " + utSpotterId);
    console.log("URL original: " + currentUrl);
    console.log("URL path: " + currentUrl);
    console.log("URL list: " + monitoredUrls);
    console.log("Tipo Usuario: " + tipoUsuario);
  }

  if (monitoredUrls.includes(currentUrl)) {
    if (utDebug) console.log("URL presente na lista. Registrando...");

    registerUsage(currentUrl);
  } else {
    if (utDebug) console.log("URL não presente na lista.");
  }

  function registerUsage(url) {
    var payload = {
      EmpresaClienteId: utEcId,
      UsuarioId: utUserId,
      Feature: url,
      Spotter: utSpotterId,
      Environment: utEnvId,
      TipoUsuario: tipoUsuario,
    };

    if (utDebug) console.log(payload);

    var utUrl = "https://usagetracker.exactsales.com.br/api/v1.0/acessos/Store";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", utUrl, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    if (utDebug) console.log("UT não registrado pois está em DEBUG");
    else xhr.send(JSON.stringify(payload));
  }
}
