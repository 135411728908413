const props = {
  MuiDialog: {
    PaperProps: { square: true },
    maxWidth: false,
  },
  MuiIconButton: {
    disableRipple: true,
  },
  MuiCheckbox: {
    color: "primary",
  },
  MuiCard: {
    square: true,
  },
  MuiRadio: {
    color: "primary",
  },
};

export default props;
