const criarNotificacaoBrowser = async (...args) => {
  const permission = await Notification.requestPermission();
  switch (permission) {
    case "granted":
      return notificar(...args);
    case "denied":
    default:
      throw new Error(
        `Ocorreu um erro ao notificar. Permissão para notificar negada. Permissão atual: ${Notification.permission}`
      );
  }
};

const notificar = (titulo, opcoes = {}, eventos = {}, icon) => {
  const notification = new Notification(titulo, { icon, ...opcoes });
  const { onClick, onError } = eventos;
  notification.onclick = onClick;
  notification.onerror = onError;

  return notification;
};

export default criarNotificacaoBrowser;
