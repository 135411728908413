/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-undef */
/* eslint-disable no-empty */
export function spotterAdmTrack() {
  document.addEventListener("DOMContentLoaded", function() {
    try {
      var host = window.location.href;
      var isDebug = host.includes("localhost") || host.includes("file://");

      var payload = {
        CompanyId: utEcId,
        ClientId: utUserId,
        Feature: currentUrl,
        Spotter: utSpotterId,
        Environment: utEnvId,
        ClientEmail: utEmail,
        FeatureTitle: document.title || null,
      };

      var utUrl = "https://apidam.exactsales.com.br/CustomerX/Tracking";
      if (isDebug) utUrl = "https://localhost:44382/CustomerX/Tracking";

      var xhr = new XMLHttpRequest();
      xhr.open("POST", utUrl, true);
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.send(JSON.stringify(payload));
    } catch (e) {}
  });
}
