export const FILLMASK = "#########################";
export const DDI_BRASIL = "55";
export const DEFAULT_COUNTRY = DDI_BRASIL;

export const MASKCONFIG = {
  default: `(###) ${FILLMASK}`,
  1: {
    default: `(###) ###-${FILLMASK}`,
  },
  54: {
    default: `# ## ####-${FILLMASK}`,
  },
  55: {
    8: "####-####",
    9: "#####-####",
    10: "(##) ####-####",
    11: "(##) #####-####",
    "x>11": `(##) ####-${FILLMASK}`,
  },
};
