import { grey500, grey900 } from "material-ui/styles/colors";

const overrides = {
  MuiCard: {
    root: {
      margin: "24px 12px",
    },
  },
  MuiCardContent: {
    root: {
      padding: "24px 16px",
    },
  },
  MuiCardHeader: {
    root: { minHeight: 56 },
  },
  MuiChip: {
    root: {
      "&[disabled]": {
        pointerEvents: "none",
        opacity: 0.54,
      },
    },
  },
  MuiTypography: {
    root: {
      fontWeight: 400,
      opacity: 0.87,
    },
    h1: {
      fontSize: 28,
      fontWeight: 300,
    },
    h2: {
      fontSize: 20,
    },
  },
  MuiDialogTitle: {
    root: { position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" },
  },
  MuiDialogActions: {
    root: { margin: 0, padding: "8px 4px", position: "sticky", bottom: 0, backgroundColor: "white" },
  },
  MuiRadioGroup: {
    root: { marginTop: 8, marginBottom: 4 },
  },
  MuiRadio: {
    root: {
      color: "#457AB7",
    },
  },
  MuiAvatar: {
    colorDefault: { backgroundColor: "#797979" },
  },
  MuiCheckbox: {
    root: {
      color: "#457AB7",
    },
  },
  MuiIconButton: {
    root: {
      cursor: "pointer",
      color: grey500,
      fontSize: "20px",
      "&:hover": { backgroundColor: "transparent", color: grey900 },
    },
    colorPrimary: {
      "&:hover": { backgroundColor: "transparent" },
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      height: 32,
      "&:hover": { backgroundColor: "transparent" },
    },
    textPrimary: {
      "&:hover": { backgroundColor: "transparent" },
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: "black",
      borderRadius: 3,
      fontSize: 12,
      padding: 8,
    },
  },
  MuiModal: {
    root: {
      zIndex: 1501,
      margin: "auto",
    },
  },
  MuiInputBase: {
    root: {
      fontSize: 14,
      fontColor: "rgba(0,0,0,0.87)",
      fontWeight: "normal",
    },
  },
  MuiInput: {
    underline: {
      "&::before, &::after": { borderBottomWidth: "1px !important" },
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: "none",
    },
  },
  MuiSelect: {
    icon: {
      height: "20px !important",
      width: "20px !important",
    },
  },
  MuiTabs: {
    root: {
      height: "100%",
    },
    flexContainer: {
      height: "100%",
    },
  },
  MuiTableRow: {
    root: {
      height: 56,
    },
    hover: {
      "&:hover": { backgroundColor: "rgb(247,247,247) !important" },
    },
  },
  MuiFormControl: {
    fullWidth: {
      marginTop: 12,
      marginBottom: 12,
    },
  },
  MuiExpansionPanelDetails: {
    root: { borderTop: "1px solid #e1e1e1" },
  },
  MuiExpansionPanelSummary: {
    root: {
      height: 76,
      width: "100%",
      transition: "all 0.7s ease-out",
      "&:hover": { backgroundColor: "#f1f3f4" },
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
  },
  MuiExpansionPanel: {
    root: {
      overflow: "hidden",
      boxShadow: "none",
      border: "1px solid #e1e1e1",
      width: "100%",
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        borderRadius: "8px !important",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1176470588)",
      },
    },
    rounded: {
      "&:first-child": {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      },
      "&:last-child": {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
};

export default overrides;
