import React from "react";
import ReactDOM from "react-dom";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import { Provider } from "react-redux";
import Snackbar from "material-ui/Snackbar";

import { AppWrapper } from "../..";
import ConnectedIntlProvider from "../../containers/ConnectedIntlProvider/ConnectedIntlProvider";
import store from "../../../store/store";

/**
 * @param {string | MessageDescriptor | MessageDescriptorWithValue} data
 * @param {string=} id Utilizado principalmente para ajudar nos testes automatizados
 */
export default function createSnackBar(data, tempoEmTela, id = "snackBarMensagem") {
  ReactDOM.render(
    <Provider store={store}>
      <AppWrapper>
        <ConnectedIntlProvider>
          <Snack autoHideDuration={tempoEmTela} data={data} id={id} />
        </ConnectedIntlProvider>
      </AppWrapper>
    </Provider>,
    document.getElementById("snackbar")
  );
}

Snack.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  tempoEmTela: PropTypes.number,
};

function Snack({ data, id, ...props }) {
  const Component = getSnackComponent(data);
  return <Component data={data} id={id} style={style} open {...props} />;
}

SnackWithString.propTypes = {
  data: PropTypes.string,
};

function SnackWithString({ data, ...props }) {
  const bodyStyle = getBodyStyle(data);
  return <Snackbar message={data} bodyStyle={bodyStyle} {...props} />;
}

SnackWithMessageDescriptor.propTypes = {
  /** MessageDescriptor */
  data: PropTypes.object,
};

function SnackWithMessageDescriptor({ data, ...props }) {
  const intl = useIntl();
  const bodyStyle = getBodyStyle(data.defaultMessage);
  return <Snackbar message={intl.formatMessage(data)} bodyStyle={bodyStyle} {...props} />;
}

SnackWithMessageDescriptorAndValue.propTypes = {
  /** MessageDescriptorWithValue */
  data: PropTypes.shape({
    /* MessageDescriptor */
    message: PropTypes.object,
    /* Valores para interpolação da mensagem */
    values: PropTypes.object,
  }),
};

function SnackWithMessageDescriptorAndValue({ data, ...props }) {
  const intl = useIntl();
  const { message, values } = data;
  const bodyStyle = getBodyStyle(message);
  return <Snackbar message={intl.formatMessage(message, values)} bodyStyle={bodyStyle} {...props} />;
}

const DATA_TYPES_COMPONENT = {
  STRING: SnackWithString,
  MESSAGE_DESCRIPTOR: SnackWithMessageDescriptor,
  MESSAGE_DESCRIPTOR_AND_VALUE: SnackWithMessageDescriptorAndValue,
};

const getSnackComponent = data => {
  let Component = () => null;

  if (typeof data === "string") {
    Component = DATA_TYPES_COMPONENT.STRING;
  }

  if (data?.defaultMessage) {
    Component = DATA_TYPES_COMPONENT.MESSAGE_DESCRIPTOR;
  }

  if (data?.message?.defaultMessage) {
    Component = DATA_TYPES_COMPONENT.MESSAGE_DESCRIPTOR_AND_VALUE;
  }

  return Component;
};

const style = {
  left: 56,
  bottom: 16,
  transform: "translate(0px, 0px)",
};

const SINGLE_LINE_MAX_LENGTH = 70;

const multilineBodyStyle = {
  height: "auto",
  lineHeight: "28px",
  padding: 12,
  whiteSpace: "pre-line",
};

const getBodyStyle = (msg = "") => (msg.length > SINGLE_LINE_MAX_LENGTH ? multilineBodyStyle : {});

/**
 * @typedef {Object} MessageDescriptor
 * @property {string} defaultMessage Required
 * @property {string=} description
 * @property {string=} id
 */

/**
 * @typedef {Object} MessageDescriptorWithValue
 * @property {MessageDescriptor} message
 * @property {object=} values React-Intl Record
 */
