import React from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => (
  <TextField errorText={touched && error} {...input} {...custom} />
);

renderTextField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export default renderTextField;
