import { definirPerfilUsuarioStartUp } from "../helperContantes/perfilHelper";

/* eslint-disable no-undef */
export const addPesquisaQuestionario = usuario => {
  const scriptQryz = document.createElement("script");
  scriptQryz.type = "text/javascript";
  scriptQryz.src = "https://assets.qeryz.net/survey/js/qryz_v3.2.js";
  document.body.appendChild(scriptQryz);

  initializeQryz();

  const scriptInitQryz = document.createElement("script");
  scriptInitQryz.type = "text/javascript";
  scriptInitQryz.innerHTML = `(${initializeQryz.toString()})();`;
  document.body.appendChild(scriptInitQryz);

  const scriptTrackLoggedIn = document.createElement("script");
  scriptTrackLoggedIn.type = "text/javascript";
  scriptTrackLoggedIn.innerHTML = `qRz.push(['QrzTrackLoggedIn', {'name': ${definirPerfilUsuarioStartUp(
    usuario.tipoPerfil
  )}, 'email': ${usuario.email}}]);`;
  document.body.appendChild(scriptTrackLoggedIn);
};

function initializeQryz() {
  setTimeout(() => {
    const qryzPlks = document.createElement("div");
    qryzPlks.id = "qryzPlks";
    qryzPlks.className = "qryzPlks";
    document.body.appendChild(qryzPlks);
    qryzInit2("5484");
  }, 0);
}
