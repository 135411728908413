import { CachedRequests } from "../cachedRequests";
import { ExactWebPhone } from "../../../_common";
import localStorageManager from "../storage";
import {
  INFOS_USUARIO,
  ENV,
  JWT_TOKEN,
  RECAPTCHA,
  REFRESH_TOKEN,
  BUSCA_AVANCADA,
  TIPO_PLANO,
  DASHBOARD_PERIODO_PADRAO,
  ATUALIZAR_DASHBOARD,
  ADICIONAR_SERVICE_WORKER,
} from "../storage/constantesStorage";
import inlineManualShutdown from "../startUpApplication/inlineManualShutdown";
import socket from "../../../websocket/socket";
import { unregister } from "../../containers/App/serviceWorkerRegistration";

const isLogado = () => {
  const token = localStorageManager.getItem(JWT_TOKEN);
  const isUserLogado = token !== null;
  return isUserLogado;
};

export const deslogar = () => {
  if (localStorageManager.getItem(ADICIONAR_SERVICE_WORKER)) {
    unregister();
  }
  ExactWebPhone().clear();
  inlineManualShutdown();
  localStorageManager.removeItems([
    JWT_TOKEN,
    ENV,
    INFOS_USUARIO,
    REFRESH_TOKEN,
    RECAPTCHA,
    BUSCA_AVANCADA,
    TIPO_PLANO,
    DASHBOARD_PERIODO_PADRAO,
    ATUALIZAR_DASHBOARD,
    ADICIONAR_SERVICE_WORKER,
  ]);
  CachedRequests.clear();
  socket.disconnect();
  socket.notificarOutrasAbasDisconexaoSocket();
};

export default isLogado;
