import createDialogConfirm from "./createDialogConfirm";

export default function asyncConfirm(dialogProps) {
  return new Promise((resolve, reject = x => x) =>
    createDialogConfirm({
      ...dialogProps,
      callback: confirmed => (confirmed ? resolve() : reject()),
    })
  );
}
