import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "material-ui";

const renderCheckbox = ({ input, label, ...rest }) => (
  <Checkbox label={label} checked={input.value} onCheck={input.onChange} {...input} {...rest} />
);

renderCheckbox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};

export default renderCheckbox;
