/**
 * módulo criado pra facilitar o uso das permissões
 * o default export é o objeto que contém as permissões indexadas pela chave
 * ele possui dois named exports:
 * - getGroupedPermissions: id da permissão agrupadas pelo texto do grupo, pra exibir na configuração
 * - PERMISSIONS: permissões indexadas pelo id, pra facilitar a obtenção dos detalhes da permissão
 */

import { findCommonElement } from "../utils/array";
import PERMISSOES from "./permissions";

// array of [ { texto: String, funcionalidades: [Number] } ]
export const getGroupedPermissions = (accessGroups = []) => {
  let permissions = PERMISSOES;

  if (accessGroups?.length) {
    permissions = Object.keys(PERMISSOES).filter(key => {
      const targetAccessGroups = PERMISSOES[key].accessGroups;
      return targetAccessGroups ? findCommonElement(targetAccessGroups, accessGroups) : true;
    });
  } else {
    permissions = Object.keys(PERMISSOES);
  }

  return permissions.reduce((groups, key) => {
    const permission = PERMISSOES[key];
    let group = { funcionalidades: [] };

    const groupIndex = groups.findIndex(g => g.texto === permission.grupo);
    if (groupIndex >= 0) group = groups.splice(groupIndex, 1)[0];

    return [...groups, { texto: permission.grupo, funcionalidades: [...group.funcionalidades, permission.id] }];
  }, []);
};

// named export object of { id: { descricao: String, binaria: Boolean } }
export const PERMISSIONS_DETAILED = Object.keys(PERMISSOES).reduce((permissions, key) => {
  const permission = PERMISSOES[key];
  return {
    ...permissions,
    [permission.id]: PERMISSOES[key],
  };
}, {});

// exported permissions are present here and in permissions.js
export default {
  ALTERAR_DOR_PRODUTO: 1,
  ALTERAR_CAMPOS_PERSONALIZADOS: 14,
  ALTERAR_EMPRESA: 15,
  ALTERAR_CONTATOS: 16,
  ALTERAR_OBSERVACOES: 17,
  APAGAR_LEAD: 2,
  DESCARTAR: 4,
  EXPORTAR: 5,
  MARCAR_VENDIDO: 6,
  RECUPERAR: 7,
  TRANSFERIR: 8,
  VISUALIZAR_DETALHE: 9,
  VISUALIZAR_LEAD: 10,
  COMENTAR: 11,
  EFETUAR_LIGACOES: 12,
  REAGENDAR: 13,
  APLICAR_FILTRO: 33,
  APLICAR_FEEDBACK: 34,
  REAVALIAR: 35,
  REAVALIAR_FEEDBACK: 36,
  VISUALIZAR_RESPOSTAS: 37,
  VISUALIZAR_RESPOSTAS_FEEDBACK: 38,
  AGENDA_VENDEDORES: 18,
  DISTRIBUICAO_LEADS: 19,
  DORES: 20,
  GRUPO: 21,
  MERCADO: 22,
  MOTIVO_DESCARTE: 23,
  MOTIVO_REUNIAO_NAO_OCORRIDA: 24,
  PADRAO_CARGOS: 54,
  ORIGEM_SUBORIGEM: 25,
  PRODUTO: 26,
  RELATORIO_DE_DISTRIBUICAO_DE_LEADS: 27,
  RESULTADO_DE_LIGACAO: 28,
  DASHBOARDCUSTOM: 55,
  BA: 30,
  CADENCIA_EMAILS: 31,
  SEARCHING: 32,
  ABRIR_AUDIO_DAS_LIGACOES: 39,
  ENCONTRARCONTATOS: 41,
  SOMATORIOPRODUTOS: 42,
  PAINEL_TELEFONIA: 44,
  CHATSWHATSAPP: 45,
  EXTRATO_TELEFONIA: 46,
  TRANSFERIRCHATS: 43,
  MODULOATIVIDADES: 47,
  // CRIARATIVIDADE: 48,
  SINALIZAR_REUNIAO_CONFIRMADA: 49,
  REINICIAR_CICLO: 52,
  WORKFLOW: 50,
  INSERIR_WORKFLOW_MANUALMENTE: 51,
  INSERIR_CADENCIA_MANUALMENTE: 53,
  VISUALIZAR_BASE: 56,
  PULAR_ETAPAS: 60,
  PERMITIR_EXCLUIR_ORGANIZACAO: 61,
  TRANSFERIR_VENDEDOR: 62,
  ALTERAR_TIPO_REUNIAO: 63,
  PERMITIR_EXCLUIR_GRAVACAO: 64,
  MOVER_LEADS_LOTE: 65,
};

export { default as hasPermission } from "./hasPermission";
export { default as hasPermissionSimple } from "./hasPermissionSimple";
