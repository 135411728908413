import { verifyRemoveNode } from "./errors/verifyRemoveNode";

export default function download(url, filename) {
  const ID = "downloadContent";

  const a = window.document.createElement("a");
  a.href = url;
  if (filename) a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.id = ID;
  a.click();
  verifyRemoveNode(ID, "downloadContent.js", a, document.body);
}
