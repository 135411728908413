import logoDefault from "../../icones/loader_transparent.gif";

const AjaxBlackout = {
  Show(logoDiferente = logoDefault) {
    const blackoutJaExiste = document.getElementById("divBlackout") !== null;
    if (blackoutJaExiste) return;

    const divBloqueio = document.createElement("div");
    divBloqueio.id = "divBlackout";

    const divBloqueioInner = document.createElement("div");
    divBloqueioInner.id = "divBlackoutInner";

    const blackoutStyle = {
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      position: "fixed",
      height: "100%",
      width: "100%",
      zIndex: "9999",
    };

    const keys = Object.keys(blackoutStyle);
    keys.forEach(key => {
      divBloqueio.style[key] = blackoutStyle[key];
      divBloqueioInner.style[key] = blackoutStyle[key];
    });

    divBloqueio.style.backgroundColor = "white";
    divBloqueio.style.opacity = "0.33";
    divBloqueioInner.style.backgroundImage = `url(${logoDiferente})`;
    divBloqueioInner.style.backgroundRepeat = "no-repeat";
    divBloqueioInner.style.backgroundPosition = "center center";
    divBloqueio.appendChild(divBloqueioInner);
    document.body.appendChild(divBloqueio);
  },
  Hide() {
    const divBloqueioBlackout = document.getElementById("divBlackout");
    if (divBloqueioBlackout) divBloqueioBlackout.remove();
  },
};

export { AjaxBlackout };
