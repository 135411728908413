export const StatusTypeLogDataDog = Object.freeze({
  OK: "ok",
  DEBUG: "debug",
  INFO: "info",
  NOTICE: "notice",
  WARN: "warn",
  ERROR: "error",
  CRITICAL: "critical",
  ALERT: "alert",
  EMERG: "emerg",
});

export const LocalLog = Object.freeze({
  NORMAL_LOGIN: "Login_Normal",
  LOGIN_SSO: "Login_SSO",
  LOGIN_SPOTTER_ADM: "Login_Spotter_ADM",
});
