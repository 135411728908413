import { TIPO_PERFIL } from "../../constantes";
import { spotterAdmTrack } from "./scriptsStartUp/spotter-adm-track";
import { addUt } from "./scriptsStartUp/ut";

export function loadMonitorarUrls(usuario) {
  const scriptVariables = document.createElement("script");
  scriptVariables.type = "text/javascript";
  scriptVariables.innerHTML = `
    var utEcId = ${usuario.empresaClienteId};
    var utUserId = ${usuario.usuarioId};
    var utEnvId = ${usuario.branch};
    var utSpotterId = 2;
    var monitoredUrls = '${usuario.urlsMonitoradas}'.split(',');
    var utEmail = '${usuario.nome}';
    var tipoUsuario = '${TIPO_PERFIL[usuario.tipoPerfil]}';
`;
  document.body.appendChild(scriptVariables);

  const scriptSpotterAdmTrack = document.createElement("script");
  scriptSpotterAdmTrack.onload = spotterAdmTrack;
  scriptSpotterAdmTrack.type = "text/javascript";
  document.body.appendChild(scriptSpotterAdmTrack);

  const scriptUt = document.createElement("script");
  scriptUt.onload = addUt;
  scriptUt.type = "text/javascript";
  document.body.appendChild(scriptUt);
}
