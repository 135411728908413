/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from "react";
import { definirPerfilUsuarioStartUp } from "../helperContantes/perfilHelper";
import routes from "../../components/MainRoutes/constantes";

const InlineManualScript = (location, usuario) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [timeOut, seTimeOut] = useState(true);
  const [reiniciar, setReiniciar] = useState(false);
  const TEMPO_RESTART_INLINE = 30;
  const UM_MINUTO_EM_MILISEGUNDOS = 60 * 1000;
  const UM_SEGUNDO_EM_MILISEGUNDOS = 1000;
  const MINUTOS_ADICIONAIS_RESTART_INLINE = 4;
  const READY_STATE_DONE = 4;
  const HTTP_STATUS_OK = 200;

  const API_KEY_INLINE = ObterApiKeyInline(window.location.hostname, usuario);
  const inlineManualScript = `
    window.inlineManualOptions = { language: 'pt' };
    var inlinemanual_app_key="${API_KEY_INLINE}";
    !function(e,n,a,t,i,r,s){
      e[i]=e[i]||function(){
        (e[i].q=e[i].q||[]).push(arguments)
      },
      e[i].l=1*new Date,
      e[i].snippet={version:"2.0"},
      r=n.createElement(a),
      s=n.getElementsByTagName(a)[0],
      r.async=1,
      r.src=t,
      r.id=t+"-"+i,
      s.parentNode.insertBefore(r,s)
    }(window,document,"script","https://cdn.inlinemanual.com/inm/loader/loader." + inlinemanual_app_key + ".js","InlineManual");
  `;

  const scriptUserOptions = inlineManualOptions => {
    const userOptions =
      usuario == null
        ? {}
        : {
            uid: usuario.aspUserId,
            email: usuario.email,
            username: usuario.email,
            roles: [definirPerfilUsuarioStartUp(usuario.tipoPerfil)],
            group: usuario?.empresaClienteId,
            created: Math.floor((new Date(usuario.usuarioCreatedAt) - new Date(1970, 0, 1)) / 1000),
            plan: usuario?.onboardingTags ?? "",
          };
    return `window.InlineManual("boot", ${JSON.stringify(userOptions)}, ${JSON.stringify(inlineManualOptions)});`;
  };

  useEffect(
    () => {
      const routeSemInline = [routes.LOGIN.route, routes.NORMALLOGIN.route];

      if (!routeSemInline.includes(location.pathname)) {
        const scriptElement = document.createElement("script");
        scriptElement.id = "inlinemanual";
        scriptElement.innerHTML = inlineManualScript + scriptUserOptions(window.inlineManualOptions);
        document.body.appendChild(scriptElement);
        setIsFirstRender(false);
      }
    },
    [inlineManualScript, scriptUserOptions]
  );

  useEffect(
    () => {
      const reiniciarInline = async () => {
        window?.InlineManual("update");

        window?.InlineManual?.shutdown();
        removeInline();
        const scriptElement = document.createElement("script");
        scriptElement.id = "inlinemanual";
        scriptElement.innerHTML = inlineManualScript + scriptUserOptions(window.inlineManualOptions);
        document.body.appendChild(scriptElement);
        setReiniciar(() => false);
      };
      const routeSemInline = [routes.LOGIN.route, routes.NORMALLOGIN.route];

      if (routeSemInline.includes(location.pathname) || isFirstRender) {
        return;
      }
      if (reiniciar) {
        reiniciarInline();
        window.location.reload();
      }
      if (window?.InlineManual && !reiniciar) {
        window?.InlineManual("update");
      }
    },
    [location.pathname]
  );

  useEffect(() => {
    const fetchDataInline = () => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `https://cdn.inlinemanual.com/inm/data/data.${API_KEY_INLINE}.json`, true);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === READY_STATE_DONE && xhr.status === HTTP_STATUS_OK) {
          const response = JSON.parse(xhr.responseText);
          const { bundle_info } = response;
          const inlineCurrentTimeBundle = window?.InlineManual?.player?.bundle_info?.bundled_at;
          const fileChaged = inlineCurrentTimeBundle !== null && inlineCurrentTimeBundle !== bundle_info.bundled_at;
          setReiniciar(() => fileChaged);
        }
      };

      xhr.send();
    };

    const routeSemInline = [routes.LOGIN.route, routes.NORMALLOGIN.route];

    if (routeSemInline.includes(location.pathname)) {
      return () => {
        clearTimeout(timeOut);
      };
    }

    const calcularTempoRestante = () => {
      const agora = new Date();
      const minutos = agora.getMinutes();
      const segundos = agora.getSeconds();
      const milissegundos = agora.getMilliseconds();

      const minutosRestantes = TEMPO_RESTART_INLINE - (minutos % TEMPO_RESTART_INLINE);
      const tempoRestante =
        minutosRestantes * UM_MINUTO_EM_MILISEGUNDOS - segundos * UM_SEGUNDO_EM_MILISEGUNDOS - milissegundos;

      return tempoRestante;
    };

    const timeoutInicial = setTimeout(() => {
      fetchDataInline();
      const intervalo = setInterval(
        fetchDataInline,
        (TEMPO_RESTART_INLINE + MINUTOS_ADICIONAIS_RESTART_INLINE) * UM_MINUTO_EM_MILISEGUNDOS
      );

      return () => clearInterval(intervalo);
    }, calcularTempoRestante() + MINUTOS_ADICIONAIS_RESTART_INLINE * UM_MINUTO_EM_MILISEGUNDOS);
    seTimeOut(() => timeoutInicial);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
};

function removeInline() {
  const elementsInline = document.querySelectorAll('[id*="inlinemanual"],[src*="inlinemanual"]');
  elementsInline.forEach(element => element.remove());
}

const ObterApiKeyInline = (hostName, usuario) => {
  const ONBOARDING = "onboarding.exactspotter.com";
  const FRONTEND_DEV_SPA = "frontend.exactsales.dev";

  const KEY_ONBOARDING = "04699e78f3e6e1476ba61b0226e0d360";
  const KEY_FRONTEND_DEV_SPA = "e377c3729dcb87f20c27ee6edcd5f934";
  const KEY_FRONT_PRD = "3d3c3fc0fdece5b9012cbdac56140a3b";

  if (hostName.includes(ONBOARDING)) {
    console.log(`inline site: ${ONBOARDING}`);
    return KEY_ONBOARDING;
  } else if (hostName.includes(FRONTEND_DEV_SPA)) {
    console.log(`inline site: ${FRONTEND_DEV_SPA}`);
    return KEY_FRONTEND_DEV_SPA;
  } else if (usuario?.estaNoBranchDeRC) {
    return KEY_FRONT_PRD;
  }
  return KEY_FRONT_PRD;
};
export default InlineManualScript;
