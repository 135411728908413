import axios from "axios";
import EventEmitter from "events";
import localStorageManager from "../storage";
import { INFOS_USUARIO, JWT_TOKEN, REFRESH_TOKEN } from "../storage/constantesStorage";
import { IS_REFRESHING, IS_TOKEN_FINISH, TOKEN_USED } from "./broadCastApi/constantes";
import { broadCastToken } from "./broadCastApi/index";

const REFRESHED_EVENT_NAME = "refreshed";
const eventEmitter = new EventEmitter();

let isRefreshing = false;
let refreshPromise = null;
let finish = false;
const listTokenAlreadyUsed = new Set();

const supportsWebLocksApi = !!(typeof navigator !== "undefined" && navigator.locks?.request);

const getRefreshTokenApi = async () => {
  const LOCK_NAME = "refresh_token_lock";
  if (!supportsWebLocksApi) {
    const { Mutex } = await import("async-mutex");
    return new Mutex().runclusive(() => refreshTokenApi());
  }
  return navigator.locks.request(LOCK_NAME, () => refreshTokenApi());
};

/**
 * Atualiza o token JWT se necessário, evitando múltiplas chamadas simultâneas de atualização.
 * @param {string} tokenJwt - O token JWT atual.
 * @returns {Promise<{success: boolean, jwt: string|null}>} - Uma Promise resolvendo para um objeto contendo
 * a flag de sucesso e o novo token JWT, ou um objeto indicando falha.
 */

const refreshTokenApi = async () => {
  const refreshToken = getRefreshToken();
  if (!isRefreshing && !listTokenAlreadyUsed.has(refreshToken) && !finish) {
    broadCastToken.postMessage({ type: IS_REFRESHING, isRefreshing: true });

    try {
      const jwtToken = getJwtToken();
      broadCastToken.postMessage({ type: TOKEN_USED, token: refreshToken });

      const response = await axios.post("/api/pipeline/UsuarioPublico/RefreshToken", {
        AccessToken: jwtToken,
        RefreshToken: refreshToken,
      });
      const { jwt } = response.data;
      const refresh = response.data.refreshToken;
      updateTokens(jwt, refresh);
      tokenRefreshed();
      broadCastToken.postMessage({ type: IS_REFRESHING, isRefreshing: false });
      broadCastToken.postMessage({ type: IS_TOKEN_FINISH, isFinish: true });

      return { success: true, jwt };
    } catch (error) {
      broadCastToken.postMessage({ type: IS_REFRESHING, isRefreshing: false });
      return { success: false, jwt: null };
    }
  } else {
    if (!refreshPromise) {
      refreshPromise = waitForRefresh();
    }
    return refreshPromise;
  }
};

broadCastToken.onmessage = event => {
  if (event.data.type === IS_REFRESHING) {
    isRefreshing = event.data.isRefreshing;
  }
  if (event.data.type === TOKEN_USED) {
    listTokenAlreadyUsed.add(event.data.token);
  }
  if (event.data.type === IS_TOKEN_FINISH && event.data.isFinish) {
    tokenRefreshed();
    finish = true;
  }
};

function clearVariables() {
  finish = false;
  listTokenAlreadyUsed.clear();
}

/**
 * Obtém o refresh token de atualização armazenado localmente.
 * @returns {string} - O refresh token de atualização.
 */
function getRefreshToken() {
  return localStorageManager.getItem(REFRESH_TOKEN).RefreshToken;
}

/**
 * Obtém o jwt token de atualização armazenado localmente.
 * @returns {string} - O jwt token de atualização.
 */
function getJwtToken() {
  return localStorageManager.getItem(JWT_TOKEN);
}

/**
 * Atualiza os tokens JWT e de atualização na localStorage.
 * @param {string} jwt - O novo token JWT.
 * @param {Object} refreshTokenData - Os dados do novo token de atualização.
 */
function updateTokens(jwt, refreshTokenData) {
  const infosUsuario = localStorageManager.getItem(INFOS_USUARIO);
  infosUsuario.jwt = jwt;
  localStorageManager.setItem(INFOS_USUARIO, infosUsuario);

  localStorageManager.setItem(JWT_TOKEN, jwt);
  localStorageManager.setItem(REFRESH_TOKEN, {
    RefreshToken: refreshTokenData.refreshToken,
    DataExpiracao: refreshTokenData.dataExpiracao,
  });
}

/**
 * Retorna uma promessa que será resolvida quando o token JWT for atualizado.
 * @returns {Promise<{success: boolean, jwt: string|null}>} - Uma promessa que será resolvida com o novo token JWT.
 */
function waitForRefresh() {
  if (!finish) {
    return new Promise(resolve => {
      eventEmitter.once(REFRESHED_EVENT_NAME, () => {
        const jwt = localStorageManager.getItem(JWT_TOKEN);
        listTokenAlreadyUsed.delete(getRefreshToken());
        finish = false;
        resolve({ success: true, jwt });
      });
    });
  }
  return new Promise(resolve => {
    const jwt = localStorageManager.getItem(JWT_TOKEN);
    listTokenAlreadyUsed.delete(getRefreshToken());
    finish = false;

    resolve({ success: true, jwt });
  });
}

/**
 * Dispara o evento indicando que o token foi atualizado.
 */
function tokenRefreshed() {
  eventEmitter.emit(REFRESHED_EVENT_NAME);
}

export { getRefreshTokenApi, clearVariables };
