/**
 * Retorna um objeto com a mesma estrutura de tasks, contendo as respostas das promises nos values.
 * @param tasks Um objeto contendo como keys os identificadores das promises e como value as promises de fato.
 */
export const resolvePromisesIdentificadas = async tasks => {
  const promises = Object.values(tasks);
  const resultPromises = await Promise.all(promises);

  const tasksReturn = {};

  Object.keys(tasks).forEach((key, index) => {
    tasksReturn[key] = resultPromises[index];
  });

  return tasksReturn;
};

export const sleep = async (ms = 5000) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });
