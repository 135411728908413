export const IdsInlineManual = {
  LEAD_OPORTUNIDADE_NAVBAR: "lead-oportunidade-navbar",
  BTN_CADASTRAR_LEAD: "btn-cadastrar-lead",
  LEAD_OPORTUNIDADE_MENU_ICON: "lead-oportunidade-menu-icon",
  LEAD_OPORTUNIDADE_MENU_EXPANDIDO: "lead-oportunidade-menu-expandido",
  IMPORTAR_LEADS_MENU: "importar-leads-menu",
  WORKFLOW_MENU_ICON: "workflow-menu-icon",
  WORKFLOW_MENU_EXPANDIDO: "workflow-menu-expandido",
  CADENCIA_EMAILS_MENU_ICON: "cadencia-emails-menu-icon",
  CADENCIA_EMAILS_MENU_EXPANDIDO: "cadencia-emails-menu-expandido",
  WORKFLOW_AGENDAMENTO_OPTION: "workflow_agendamento_option",
  WORKFLOW_INPUT_NOME: "workflow-input-nome",
  WORKFLOW_LABEL_SEGMENTACAO: "workflow-label-segmentacao",
  DASHBOARD_MENU_ICON: "dashboard-menu-icon",
  DASHBOARD_MENU_EXPANDIDO: "dashboard-menu-expandido",
  BASE_DE_LEADS_FUNIL_MENU: "base-de-leads-funil-menu",
  CONFIGURACOES_MENU_ICON: "configuracoes-menu-icon",
  CONFIGURACOES_MENU_EXPANDIDO: "configuracoes-menu-expandido",
  ATIVIDADES_MENU: "atividades-menu",
  BA_MENU_ICON: "ba-menu-icon",
  BA_MENU_EXPANDIDO: "ba-menu-expandido",
  EQUIPE_MENU_ICON: "equipe-menu-icon",
  EQUIPE_MENU_EXPANDIDO: "equipe-menu-expandido",
  AGENDA_MENU_ICON: "agenda-menu-icon",
  AGENDA_MENU_EXPANDIDO: "agenda-menu-expandido",
  NAVBAR_USUARIO_NOME: "usuario-spotter-menu",
  POPOVER_SUA_CONTA: "sua-conta-menu",
  BTN_DETALHES_LEAD: "btn-detalhes-lead",
  FUNIL_PRIMEIRA_ETAPA: "primeira-coluna-funil",
};
