import { grey100, grey300, grey500, white, darkBlack, fullBlack } from "material-ui/styles/colors";
import spacing from "material-ui/styles/spacing";
import { fade } from "material-ui/utils/colorManipulator";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { createMuiTheme } from "@material-ui/core/styles";
import { set } from "dot-prop-immutable";

import overrides from "./temaOverrides";
import props from "./temaProps";

export const theme = {
  typography: {
    useNextVariants: true,
  },
  spacing,
  fontFamily: "Roboto, sans-serif",
  borderRadius: 2,
  palette: {
    primary1Color: "#3d69a0",
    primary2Color: "#394554",
    primary3Color: "#a1c3ed",
    accent1Color: "#f5845e",
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    clockCircleColor: fade(darkBlack, 0.07),
    secondaryTextColor: fade(darkBlack, 0.54),
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.38),
    pickerHeaderColor: white,
    shadowColor: fullBlack,
    backgroundRowHover: "#f7f7f7",
    text: {
      darkPrimaryText: darkBlack,
      darkSecondaryText: fade(darkBlack, 0.54),
      darkHintText: fade(darkBlack, 0.38),
      darkDividers: fade(darkBlack, 0.12),
      lightPrimaryText: white,
      lightSecondaryText: fade(white, 0.7),
      lightHintText: fade(white, 0.5),
      lightDividers: fade(white, 0.12),
    },
    icons: {
      darkFocusedIcon: fade(darkBlack, 0.87),
      darkActiveIcon: fade(darkBlack, 0.54),
      darkInactiveIcon: fade(darkBlack, 0.38),
      lightFocusedIcon: white,
      lightActiveIcon: fade(white, 0.7),
      lightInactiveIcon: fade(white, 0.5),
    },
  },
  tabs: {
    backgroundColor: white,
    selectedTextColor: darkBlack,
    textColor: fade(darkBlack, 0.54),
  },
  button: {
    height: 32,
  },
};

const temaExactMuiV0 = getMuiTheme(theme);

export const temaExactMuiV1 = createMuiTheme({
  ...set(temaExactMuiV0, "palette", palette => ({
    ...palette,
    status: {
      red: "#EC4A2C",
      yellow: "#FFA922",
      green: "#0F9D58",
      gray: "#5E5E5E",
      blue: "#41ADCF",
    },
    primary: {
      light: "#A1C3ED",
      main: "#457AB7",
      dark: "#286090",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#F5F5F5",
      main: "#F5845E",
      dark: "#9E9E9E",
      contrastText: "#FFFFFF",
    },
  })),
  props,
  overrides,
});

export default temaExactMuiV0;
