import jwtDecode from "jwt-decode";

/**
 * Usado para centralizar as manipulações com o localStorage
 */
const localStorageManager = {
  /**
   * Seta item do storage
   * @param {string} key - chave do item do localStorage
   * @param {Object} value - valor do item do localStorage
   */
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  /**
   * Obtém item do storage
   * @param {string} key - chave do item do localStorage
   */
  getItem: key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },

  /**
   * Remove item do storage
   * @param {string} key - chave do item do localStorage
   */
  removeItem: key => {
    localStorage.removeItem(key);
  },

  /**
   * Remove items do storage
   * @param {Array} keys - chaves dos items do localStorage
   */
  removeItems: keys => {
    keys.forEach(key => {
      localStorage.removeItem(key);
    });
  },

  /**
   * Remove todos os items do storage
   */
  clearAll: () => {
    localStorage.clear();
  },

  isLocalStorageEmpty: () => localStorage.length === 0,

  isTokenTimeValid: token => {
    if (!token) {
      return false;
    }

    try {
      const jwt = jwtDecode(token);
      const expirationTimestamp = jwt.exp * 1000;
      return timeValid(expirationTimestamp);
    } catch (error) {
      return false;
    }
  },
  isRefreshTokenTimeValid: refreshTokenDate => {
    if (!refreshTokenDate) {
      return false;
    }

    try {
      return timeValid(refreshTokenDate);
    } catch (error) {
      return false;
    }
  },
};

function timeValid(expirationTime) {
  const currentTime = Date.now();
  return currentTime < expirationTime;
}

export default localStorageManager;
