import { TIPO_PERFIL } from "../../constantes";

function obterTipoPerfil(numero) {
  const tipo = Object.keys(TIPO_PERFIL).find(key => TIPO_PERFIL[key] === numero);
  return tipo || null;
}

/**
 * Retorna o tipo de perfil / Foi copiado a lógica do método OnActionExecuting do ExactConrtoller.
 * @param {number} numero - O número do perfil a ser procurado.
 * @returns {string} O tipo de perfil correspondente ao número fornecido, ou Gerente caso não encontre nenhum/Lógica já existente no método comentado acima.
 */
export function definirPerfilUsuarioStartUp(numero) {
  const tipo = obterTipoPerfil(numero);
  switch (tipo) {
    case "PRE_VENDEDOR":
      return "PreVendedor";
    case "VENDEDOR":
      return "Vendedor";
    default:
      return "Gerente";
  }
}
