import { forEach, isPlainObject, isArray, camelCase, upperFirst } from "lodash";
import moment from "moment";
import { curry, compose } from "lodash/fp";
import { LABEL_INATIVO } from "../../messages";
import { appIntl } from "../../containers/ConnectedIntlProvider";
import { verifyRemoveNode } from "../errors/verifyRemoveNode";

export const getIniciaisDeUmNome = nome => {
  if (!nome) return " ";

  const arrayNomes = nome.trim().split(" ");
  const inicial1 = arrayNomes[0][0].toUpperCase();

  if (arrayNomes.length === 1) {
    return inicial1;
  }
  if (!arrayNomes[1][0]) return inicial1;
  const inicial2 = arrayNomes[1][0].toUpperCase();
  return inicial1 + inicial2;
};

export const escapeHtml = html =>
  html
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

export const unescapeHtml = html =>
  html
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, `"`)
    .replace(/&#0?39;/g, "'");

export const validarEmail = email => {
  const regexEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(email);
};

export const validarHiperLink = link => {
  if (link !== "" && link !== undefined) {
    const regexLink = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    return regexLink.test(link.trim());
  }

  return true;
};

export function toUpperCamel(ob) {
  if (typeof ob !== "object" || Array.isArray(ob) || !ob) return ob;
  const upper = {};
  Object.keys(ob).forEach(el => {
    upper[el.replace(/\w/, x => x.toUpperCase())] = toUpperCamel(ob[el]);
  });
  return upper;
}

export const objectKeysToCamelCase = dado => {
  if (!isPlainObject(dado) && !isArray(dado)) {
    return dado;
  }

  if (isArray(dado)) {
    return arrayOfObjectsToCamelCase(dado);
  }

  const camelCaseObject = {};
  forEach(dado, (value, key) => {
    const newValue = objectKeysToCamelCase(value);
    camelCaseObject[camelCase(key)] = newValue;
  });

  return camelCaseObject;
};

const arrayOfObjectsToCamelCase = arr => {
  const arrCamelCase = arr.map(elem => objectKeysToCamelCase(elem));
  return arrCamelCase;
};

export const parseSecondsToHours = seconds => {
  const addZeroLeft = numberToParse => numberToParse.toString().padStart(2, "0");

  const hour = addZeroLeft(Math.trunc(seconds / 3600, 10));
  const minute = addZeroLeft(Math.trunc((seconds % 3600) / 60, 10));
  const second = addZeroLeft((seconds % 3600) % 60, 10);

  return `${hour}:${minute}:${second}`;
};

export const convertStyleStringToObject = (styleString, styles = {}) => {
  const ruleList = styleString.split(";");

  ruleList.forEach(rule => {
    if (!rule.trim()) {
      return;
    }
    const [ruleName, ruleVal] = rule.split(":");
    let ruleNameNormalized = camelCase(ruleName.trim());
    // Cuida de casos de css como '-webkit-text-size-adjust'
    if (ruleName.startsWith("-")) {
      ruleNameNormalized = upperFirst(ruleNameNormalized);
    }
    styles[ruleNameNormalized] = ruleVal.trim();
  });
  return styles;
};

export const validaTelefone = telefone => {
  const possuiLetras = new RegExp("[a-z]", "i");

  if (!telefone || possuiLetras.test(telefone) || telefone.length > 13 || telefone.length < 8) {
    return false;
  }

  return true;
};

export const validaPossuiMascara = telefone => {
  const mascara = telefone.replace(/[0-9]/g, "");

  return mascara.length > 0;
};

export const removeMascaraTelefone = telefone => (telefone ? telefone.replace(/\D/g, "") : "");

export const adicionaMascaraTelefone = (telefone, possuiMascara) => {
  const telefoneSemMascara = removeMascaraTelefone(telefone);

  if (!validaTelefone(telefoneSemMascara)) return telefone;

  if (possuiMascara && validaPossuiMascara(telefone)) return telefone;

  let telefoneFormatado;

  switch (telefoneSemMascara.length) {
    case 8:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{4})(\d{4}).*/, "$1-$2");
      break;
    case 9:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{5})(\d{4}).*/, "$1-$2");
      break;
    case 10:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3");
      break;
    case 11:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
      break;
    case 12:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{2})(\d{2})(\d{4})(\d{4}).*/, "+$1 ($2) $3-$4");
      break;
    case 13:
      telefoneFormatado = telefoneSemMascara.replace(/^(\d{2})(\d{2})(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
      break;
    default:
      telefoneFormatado = "";
      break;
  }

  return telefoneFormatado;
};

export const copyToClipboard = valor => {
  const ID = "copyToClipboard";

  const input = document.createElement("input");
  input.style.position = "fixed";
  input.style.opacity = 0;
  input.value = valor;
  input.id = ID;
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");

  verifyRemoveNode(ID, "string.js", input, document.body);
};

export const copyToClipboardFromModal = (value, id) => {
  const ID = "copyToClipboardFromModal";

  const input = document.createElement("input");
  input.style.position = "fixed";
  input.style.opacity = 0;
  input.value = value;
  input.id = ID;
  const parent = document.getElementById(id) || document;
  parent.appendChild(input);
  input.select();
  document.execCommand("copy");

  verifyRemoveNode(ID, "string.js", input, parent);
};

export const formataCampoPesquista = busca => busca.replace("&", "%26");

export const adicionaCustomizacaoTelefone = (telefone, configCustom) => {
  if (configCustom) {
    return configCustom.replace("{tel}", telefone.replace(/[^0-9]/g, "")).replace(" ", "");
  }

  return configCustom;
};

export const appendPlural = n => (n > 1 ? "s" : "");

/**
 * Replace especial chars with `replacement`
 * @param {String} replacement
 * @returns {(str: String) => String} => string
 */
export const replaceSpecialChars = (replacement = "") => (str = "") => str.replace(/[.*+?^${}()|[\]\\]/g, replacement);

/**
 * Escaping a string for RegExp usage.
 * Prevents regexp to break when it's build based on user input
 */
export const escapeRegExp = replaceSpecialChars("\\$&");

/**
 * Remove chars that need to be escaped.
 */
export const removeSpecialChars = replaceSpecialChars("");

/**
 * Format number according to BRL
 */
export const formatCurrency = (value = 0) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);

export const formatDuration = (seconds = 0) => {
  const pad = num => (num.toString().length > 2 ? `${num}`.slice(-(num.length + 1)) : `0${num}`.slice(-2));

  let secs = seconds;
  let minutes = Math.floor(secs / 60);
  const hours = Math.floor(minutes / 60);
  secs %= 60;
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export const formatDate = (date, format = "DD/MM/YYYY [às] HH:mm") => moment.utc(date).format(format);

export const truncateString = (str = "", charCount, ellipsis = "...") => {
  const useEllipsis = str.length > charCount;
  return str.slice(0, charCount).concat(useEllipsis ? ellipsis : "");
};

export const onlyDigits = (val = "") => {
  try {
    return String(val).replace(/\D/g, "");
  } catch (err) {
    return val;
  }
};

/**
 * Corrige links externos que não tenham sido inseridos com o protocolo.
 * Dessa forma, o elemento <a href="..."> consegue redirecionar corretamente.
 * @see https://stackoverflow.com/questions/43803778/href-without-https-prefix
 * @param {string} link
 */
export const normalizeLinkProtocol = (link = "") =>
  link.startsWith("http://") || link.startsWith("https://") ? link : `//${link}`;

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * Aplica um número de telefone ao link customizado,
 * utilizando o valor {tel} no link
 * @param {string} telefone eg `(48)123456`
 * @param {string} customLink eg `tel:{tel}`
 * @returns {string} eg `tel:{48123456}`
 */
export const formataLinkTelefone = (telefone, customLink = "") => {
  if (!customLink.includes("{tel}")) {
    // eslint-disable-next-line
    console.error("O link de telefone deve conter '{tel}'");
    return customLink;
  }

  return customLink.replace("{tel}", onlyDigits(telefone));
};

export const prependStringWith = curry((textPrepend, str) => `${textPrepend}${str}`);
export const cropString = curry(
  (maxLength, str) => (maxLength && str.length > maxLength ? str.substring(0, maxLength) : str)
);
export const prependCopiaDe = (str = "", maxLength = 0) => {
  const intl = appIntl();
  const prepend = compose(
    cropString(maxLength),
    prependStringWith(`${intl.formatMessage({ defaultMessage: "Cópia de" })} `)
  );
  return prepend(str);
};

export const clearBase64Uri = (b64Uri = "") => b64Uri.replace(/^data:.+;base64,/, "");

export const removeDiacritics = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export function substituirOcorrencias(texto = "", ocorrencias = {}) {
  return Object.keys(ocorrencias).reduce((textoNormalizado, ocorrencia) => {
    const regex = new RegExp(ocorrencia, "gi");

    if (ocorrencias[ocorrencia] && regex.test(textoNormalizado)) {
      return textoNormalizado.replace(regex, ocorrencias[ocorrencia]);
    }

    return textoNormalizado;
  }, texto);
}

export const objectToQueryString = (params, rootKey = "") =>
  Object.keys(params)
    .sort()
    .map(key => {
      const value = params[key];
      if (Array.isArray(value)) return `${rootKey}${key}=${value.join(",")}`;
      if (typeof value === "object") return objectToQueryString(value, `${rootKey}${key}.`);
      return `${rootKey}${key}=${value}`;
    });

export const appendLabelInativo = (item, intl) => ({
  ...item,
  descricao: `${item.descricao}${item.ativo ? "" : ` ${intl.formatMessage(LABEL_INATIVO)}`}`,
});

export const mergeUrlQuerystring = (url, queryString) => {
  const hasQuery = url.includes("?");
  return `${url}${hasQuery ? "&" : "?"}${queryString}`;
};

export const getUrlWithParams = (url, params) => {
  const queryString = objectToQueryString(params);
  return mergeUrlQuerystring(url, queryString);
};

export function toPascalCase(str) {
  return str.toLowerCase().replace(/(?:^|_)([a-z])/g, (_, char) => char.toUpperCase());
}

export function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}

export function getErrorMessage(error) {
  return error?.response?.data?.ExceptionMessage || error.message;
}
