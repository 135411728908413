const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 20,
});

export function numberToCurrency(value = 0) {
  const formattedValue = formatter.format(value);
  return formattedValue.substr(0, formattedValue.indexOf(",") + 3);
}

export function currencyToNumber(currency) {
  return Number(
    currency
      .replace(" %", "")
      .replace("R$ ", "")
      .replace(",", ".")
  );
}
