import { sanitize, addHook } from "dompurify";
import { walk, generate, parse } from "css-tree";
import cuid from "cuid";
import pipe from "pipe-now";

export const tratarHtmlPerigoso = originalHtml => {
  const options = {
    ALLOWED_TAGS: [
      "html",
      "style",
      "title",
      "body",
      "address",
      "article",
      "aside",
      "footer",
      "header",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "hgroup",
      "nav",
      "section",
      "blockquote",
      "dd",
      "div",
      "dl",
      "dt",
      "figcaption",
      "figure",
      "hr",
      "li",
      "main",
      "ol",
      "p",
      "pre",
      "ul",
      "a",
      "abbr",
      "b",
      "br",
      "cite",
      "code",
      "data",
      "em",
      "i",
      "mark",
      "q",
      "s",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "time",
      "wbr",
      "img",
      "noscript",
      "caption",
      "col",
      "colgroup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
      "label",
      "legend",
      "big",
      "center",
      "font",
      "strike",
      "u",
    ],
  };
  addHook("afterSanitizeAttributes", node => {
    addTargetToAnchors(node);
  });
  const cssScope = `e${cuid.slug()}`;
  const htmlFormatado = pipe(
    `<div class="${cssScope}">${originalHtml}</div>`,
    val => sanitize(val, options),
    val => randomizarSeletoresCss(val, cssScope),
    val => aplicarEscopoTagsStyle(val, cssScope),
    val => removerConflitosBootstrap(val, cssScope)
  );

  return htmlFormatado;
};

export const randomizarSeletoresCss = (originalHtml, cssScope) => {
  const placeholderDiv = document.createElement("div");
  const classNamesDictionary = { [cssScope]: cssScope };
  placeholderDiv.innerHTML = originalHtml;

  const elementosComClasses = placeholderDiv.querySelectorAll("[class]");

  const gerarNovaClasse = classe => `c_${classe}_${cuid()}`;

  elementosComClasses.forEach(elemento => {
    const classes = Array.from(elemento.classList).map(classe => {
      const novaClasse = classNamesDictionary[classe] || gerarNovaClasse(classe);
      classNamesDictionary[classe] = novaClasse;
      return novaClasse;
    });

    elemento.className = classes.join(" ");
  });

  const styleNodeList = placeholderDiv.querySelectorAll("style");

  styleNodeList.forEach(styleNode => {
    const ast = parse(styleNode.innerHTML);

    walk(ast, node => {
      switch (node.type) {
        case "ClassSelector": {
          if (!classNamesDictionary[node.name]) {
            classNamesDictionary[node.name] = gerarNovaClasse(node.name);
          }
          node.name = classNamesDictionary[node.name];
          break;
        }

        default:
          break;
      }
    });

    const scopedCss = generate(ast);

    styleNode.innerHTML = scopedCss;
  });

  return placeholderDiv.innerHTML;
};

export const aplicarEscopoTagsStyle = (originalHtml, scope) => {
  const placeholderDiv = document.createElement("div");
  placeholderDiv.innerHTML = originalHtml;

  const styleNodeList = placeholderDiv.querySelectorAll("style");

  styleNodeList.forEach(styleNode => {
    const ast = parse(styleNode.innerHTML);

    walk(ast, (node, item, list) => {
      switch (node.type) {
        case "AttributeSelector":
        case "TypeSelector":
        case "IdSelector":
        case "ClassSelector":
          if (item.prev === null) {
            list.insertData(
              {
                loc: null,
                name: scope,
                type: "ClassSelector",
              },
              item
            );
            list.insertData(
              {
                type: "WhiteSpace",
                loc: null,
                value: " ",
              },
              item
            );
          }
          break;

        default:
          break;
      }
    });

    const scopedCss = generate(ast);

    styleNode.innerHTML = scopedCss;
  });

  return placeholderDiv.innerHTML;
};

export const removerConflitosBootstrap = (html, cssScope) => {
  const placeholderDiv = document.createElement("div");

  placeholderDiv.innerHTML = pipe(
    html,
    transformarAtributosEmStyle,
    val => adicionarStyleAnulaBootstrap(val, cssScope)
  );

  return placeholderDiv.innerHTML;
};

const transformarAtributosEmStyle = html => {
  const placeholderDiv = document.createElement("div");
  placeholderDiv.innerHTML = html;

  const bgColors = [...placeholderDiv.querySelectorAll("[bgcolor]")];

  bgColors.forEach(bg => {
    bg.style.backgroundColor = bg.bgColor;
  });

  return placeholderDiv.innerHTML;
};

const adicionarStyleAnulaBootstrap = (html, cssScope) => {
  const placeholderDiv = document.createElement("div");
  placeholderDiv.innerHTML = html;

  const style = `
    .${cssScope} * {
      box-sizing: initial;
      border-spacing: initial;
      border-collapse: initial;
    }
  `.trim();

  const styleElement = document.createElement("style");
  styleElement.innerHTML = style;

  placeholderDiv.querySelector(`.${cssScope}`).appendChild(styleElement);

  return placeholderDiv.innerHTML;
};

const addTargetToAnchors = node => {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
};

export const setImportant = value => `${value}${typeof value === "number" ? "px" : ""} !important`;
