import { definirPerfilUsuarioStartUp } from "../helperContantes/perfilHelper";

export const addNPS = usuario => {
  const dateUserCreatedAt = new Date(Date.parse(usuario.usuarioCreatedAt));
  if (!(dateUserCreatedAt instanceof Date)) {
    throw new Error("usuario.CreatedAt must be a Date object");
  }

  const epoch = new Date(1970, 0, 1);
  const userCreatedDateInMilliseconds = dateUserCreatedAt.getTime();
  const differenceInMilliseconds = userCreatedDateInMilliseconds - epoch.getTime();

  if (differenceInMilliseconds > Number.MAX_SAFE_INTEGER * 1000) {
    console.error("Unix timestamp might overflow Int32 range. Consider using BigInt.");
  }

  const unixTimestamp = Math.floor(differenceInMilliseconds / 1000);

  const configShowWootricDev = usuario?.estaNoAmbienteDeDesenvolvimento
    ? "wootric_survey_immediately=true; wootric_no_surveyed_cookie = true;"
    : "";

  const setupScript = document.createElement("script");
  setupScript.type = "text/javascript";
  setupScript.id = "wootric-settings";
  setupScript.async = true;
  setupScript.innerHTML = `

  ${configShowWootricDev}

  window.wootricSettings = {
    email: '${usuario.email}',
    created_at: ${unixTimestamp},
    account_token: 'NPS-d2f8556e',
    external_id: '${usuario.empresaClienteId}',
    properties: {
        ambiente: 'SV2',
        perfil: '${definirPerfilUsuarioStartUp(usuario.tipoPerfil)}',
        empresaclienteid: '${usuario.empresaClienteId}'
    },
  };
`;
  if (document.getElementById("wootric-settings") == null) {
    document.body.appendChild(setupScript);
  }

  const beacon = document.createElement("script");
  beacon.type = "text/javascript";
  beacon.id = "wootric-beacon";
  beacon.async = true;

  beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
  beacon.onload = function() {
    window.wootric("run");
  };
  if (document.getElementById("wootric-beacon") == null) {
    document.body.appendChild(beacon);
  }
};
