/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable no-shadow */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-spread */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
/* eslint-disable prettier/prettier */
export function loadGoogleTagManager() {
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-5DD7XLCV");

  addScriptGoogleTagManager();
}
const addScriptGoogleTagManager = () => {
  const scriptElement = document.createElement("script");

  scriptElement.src = "https://www.googletagmanager.com/gtag/js?id=G-XEQQCQWRTF";

  scriptElement.async = true;

  document.head.appendChild(scriptElement);
};

export const addNoScriptGoogleManager = () => {
  var noscriptElement = document.createElement("noscript");

  var iframeElement = document.createElement("iframe");
  iframeElement.src = "https://www.googletagmanager.com/ns.html?id=GTM-5DD7XLCV";
  iframeElement.height = "0";
  iframeElement.width = "0";
  iframeElement.style.display = "none";
  iframeElement.style.visibility = "hidden";

  noscriptElement.appendChild(iframeElement);

  document.body.appendChild(noscriptElement);
};
