export const getDataFromResponse = resp => resp.data;

export const yupToFormErrors = yupError => {
  const errors = {};
  yupError.inner.forEach(err => {
    if (!errors[err.path]) {
      errors[err.path] = err.message;
    }
  });

  return errors;
};

export const isObject = obj => obj?.constructor === Object;

export const isEmptyObject = obj => isObject(obj) && Object.keys(obj).length === 0;

export const replaceNullProperties = (pObj, defaultValue = "") => {
  const obj = pObj;
  const replace = pIt => {
    let it = pIt;
    if (typeof it === "boolean") return it;
    if (it) {
      if (Array.isArray(it)) {
        it = it.map(x => replace(x));
      } else if (typeof it === "object") {
        if (!it) it = defaultValue;
        Object.keys(it).forEach(key => {
          it[key] = replace(it[key]);
        });
      }
    } else it = defaultValue;
    return it;
  };

  if (!obj) return null;
  return replace(obj);
};

export function removeFormikProps(props) {
  const { initialError, initialTouched, initialValue, touched, validate, error, ...newProps } = props;
  return newProps;
}

export function removeEmptyFields(object) {
  return Object.keys(object).reduce((ac, key) => {
    const value = object[key];

    // se valor for array, só retorna se não for vazio
    if (Array.isArray(value)) return value.length ? { ...ac, [key]: value } : ac;

    // se valor for objeto, só retorna se não for vazio, recursivamente
    if (isObject(value) && !isEmptyObject(value)) {
      const values = removeEmptyFields(value);
      return isEmptyObject(values) ? ac : { ...ac, [key]: values };
    }

    // retorna se for false ou 0
    if (typeof value === "boolean" || Number.isFinite(value)) {
      return { ...ac, [key]: value };
    }

    // retorna se valor coagido for true
    return value ? { ...ac, [key]: value } : ac;
  }, {});
}

export const isNullOrUndefined = val => val === null || typeof val === "undefined";
