export const CachedRequests = (function() {
  const URL_TRACKER_CACHE_KEY = "CachedRequests_UrlTracker";
  const DEFAULT_TRACKER = { urls: {} };

  const getUrlTracker = () => JSON.parse(localStorage.getItem(URL_TRACKER_CACHE_KEY)) || DEFAULT_TRACKER;

  const insertUrlIntoTracker = url => {
    const tracker = getUrlTracker();
    tracker.urls[url] = true;
    localStorage.setItem(URL_TRACKER_CACHE_KEY, JSON.stringify(tracker));
  };

  const removeFromUrlTracker = url => {
    const tracker = getUrlTracker();
    delete tracker.urls[url];
    localStorage.setItem(URL_TRACKER_CACHE_KEY, JSON.stringify(tracker));
  };

  const clearUrlTracker = () => localStorage.removeItem(URL_TRACKER_CACHE_KEY);

  const DEFAULT_OPTIONS = {
    maxAge: null,
  };

  const createUrlStore = (url, value, opts) => {
    const urlStore = {
      value,
      maxAge: opts.maxAge,
      createdAt: Date.now(),
    };

    localStorage.setItem(url, JSON.stringify(urlStore));
    insertUrlIntoTracker(url);

    return urlStore;
  };

  /**
   * Retorna resultado da cache ou, caso este seja inválido, busca no servidor através da getFn
   *
   * @param {string} url URL que será consultada
   * @param {function} getFn Função assíncrona que retorna o dado que deverá ser cacheado
   * @param {CachedRequestsGetOptions} options
   */
  async function get(url, getFn, options = DEFAULT_OPTIONS) {
    const opts = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    if (getIsValidUrl(url)) {
      return getURLStore(url).value;
    }

    if (!getFn || !typeof getFn === "function") {
      // eslint-disable-next-line
      console.warn("Valor em cache está desatualizado, mas a função para atualizar não foi fornecida.");
      return getURLStore(url).value;
    }

    // Busca dados originais
    try {
      const result = await getFn();
      const newUrlStore = createUrlStore(url, result, opts);
      return newUrlStore.value;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Função usadas para chamadas síncronas
   * Retorna resultado da cache ou, caso este seja inválido, busca no servidor através da getFn
   *
   * @param {string} url URL que será consultada
   * @param {function} getFn Função que retorna o dado que deverá ser cacheado
   * @param {CachedRequestsGetOptions} options
   */
  function getSync(url, getFn, options = DEFAULT_OPTIONS) {
    const opts = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    if (getIsValidUrl(url)) {
      return getURLStore(url).value;
    }

    if (!getFn || !typeof getFn === "function") {
      // eslint-disable-next-line
      console.warn("Valor em cache está desatualizado, mas a função para atualizar não foi fornecida.");
      return getURLStore(url).value;
    }

    // Busca dados originais
    try {
      const result = getFn();
      const newUrlStore = createUrlStore(url, result, opts);
      return newUrlStore.value;
    } catch (err) {
      throw err;
    }
  }

  function invalidateUrl(url) {
    const urlStore = getURLStore(url);
    if (urlStore) {
      localStorage.removeItem(url);
    }
    removeFromUrlTracker(url);
  }

  function getIsValidUrl(url) {
    const urlStore = getURLStore(url);
    return !!urlStore && isValidMaxAge(urlStore);
  }

  /**
   * Remove todas as urls e dados cacheados
   * */
  function clear() {
    const tracker = getUrlTracker();
    Object.keys(tracker.urls).forEach(url => invalidateUrl(url));
    clearUrlTracker();
  }

  /**
   * Remove todas os dados inválidos
   * */
  function purge() {
    const tracker = getUrlTracker();
    Object.keys(tracker.urls).forEach(url => {
      if (!getIsValidUrl(url)) {
        invalidateUrl(url);
      }
    });
  }

  function isValidMaxAge(urlStore) {
    const { maxAge, createdAt } = urlStore;

    if (maxAge === null) {
      return true;
    }

    if (typeof maxAge !== "number") {
      return false;
    }

    const createdAtDate = new Date(createdAt);
    if (createdAtDate.toString() === "Invalid Date") {
      return false;
    }

    const expirationDate = new Date(createdAtDate);
    expirationDate.setSeconds(expirationDate.getSeconds() + maxAge);

    return Date.now() <= expirationDate;
  }

  function getURLStore(url) {
    return JSON.parse(localStorage.getItem(url));
  }

  return {
    get,
    invalidateUrl,
    clear,
    purge,
    getSync,
  };
})();

/**
 * @typedef {Object} CachedRequestsGetOptions
 * @property {number=} maxAge Valor em segundos que deverá ser mantido os valores em cache. Se não informado, ficará em cache até ser removido
 */
