export function removeRepetidos(value, index, self) {
  return self.indexOf(value) === index;
}

export const createObjectSorter = (prop, isAscending = true) => (objectA, objectB) => {
  const propObjectA = objectA[prop];
  const propObjectB = objectB[prop];

  if (typeof propObjectA === "number" && typeof propObjectB === "number") {
    return isAscending ? propObjectA - propObjectB : propObjectB - propObjectA;
  }

  const orderAsc = propObjectA.localeCompare(propObjectB);

  return isAscending ? orderAsc : orderAsc * -1;
};

export const splitArrayIntoChunks = (arr, chunkSize) => {
  if (!chunkSize || chunkSize < 0) {
    return arr;
  }
  const result = [];
  let i;
  let j;
  let tempArray;
  for (i = 0, j = arr.length; i < j; i += chunkSize) {
    tempArray = arr.slice(i, i + chunkSize);
    result.push(tempArray);
  }
  return result;
};

export const arrayEquals = (a, b) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);

export const findCommonElement = (arr1 = [], arr2 = []) => arr1.some(item => arr2.includes(item));

export const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

export const findDuplicatesValueAndIndex = arr =>
  arr.reduce((acc, cur, curIndex) => {
    if (arr.indexOf(cur) !== curIndex) {
      return [...acc, { index: curIndex, value: cur }];
    }
    return acc;
  }, []);
