import { maybeCallback } from "../fp";
import { AjaxBlackout, createSnackbarAPIException } from "../..";

export const ajaxBlackout = {
  show() {
    AjaxBlackout.Show();
  },
  hide() {
    AjaxBlackout.Hide();
  },
};

export const withAjaxBlackout = async (fn, onError) => {
  ajaxBlackout.show();
  try {
    return await fn();
  } catch (err) {
    if (!onError) {
      throw err;
    }
    return maybeCallback(onError)(err);
  } finally {
    ajaxBlackout.hide();
  }
};

export const withAjaxBlackoutErrorMessage = fn => withAjaxBlackout(fn, createSnackbarAPIException);
