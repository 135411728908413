/* eslint-disable no-underscore-dangle */
import Axios from "axios";
import localStorageManager from "../storage";
import isLogado, { deslogar } from "../../utils/auth/auth";
import routes from "../../../_common/components/MainRoutes/constantes";
import { JWT_TOKEN } from "../storage/constantesStorage";
import { clearVariables, getRefreshTokenApi } from "./refreshToken";

let isRefreshing = false;
let failedRequestQueue = [];

export const requestInterceptor = config => {
  const token = localStorageManager.getItem(JWT_TOKEN);
  if (token) {
    clearVariables();
    config.headers.Authorization = `${token}`;
  }
  return config;
};

export const requestInterceptorError = error => Promise.reject(error);

export const responseInterceptor = response => response;

export const errorResponseInterceptor = async error => {
  const STATUS_CODE_NAO_AUTENTICADO = 401;
  const originalRequest = error.config;
  const currentURL = window.location.pathname;
  const tokenJwt = localStorageManager.getItem(JWT_TOKEN);

  const PUBLIC_BASENAME = "/public/";
  const FEEDBACK_BASENAME = "/reuniao/";
  const CONFERENCIA_BASENAME = "/conferencia/";
  const BUSCAR_LISTA_VARIAVEIS_AMBIENTE = "/Appsetting/BuscarLista";
  const estaNoPublic = () =>
    window.location.href.includes(PUBLIC_BASENAME) ||
    window.location.href.includes(FEEDBACK_BASENAME) ||
    window.location.href.includes(CONFERENCIA_BASENAME);
  const isRotasChamadasNoLogin = () => originalRequest.url.includes(BUSCAR_LISTA_VARIAVEIS_AMBIENTE);

  if (
    error.response?.status === STATUS_CODE_NAO_AUTENTICADO &&
    !originalRequest._retry &&
    tokenJwt &&
    (currentURL !== routes.LOGIN.route || isRotasChamadasNoLogin)
  ) {
    try {
      const logado = isLogado();
      if (!isRefreshing) {
        isRefreshing = true;
        getRefreshTokenApi()
          .then(resp => {
            const { jwt, success } = resp;
            manipularResponseRefreshToken(originalRequest, jwt, success);
          })
          .catch(err => {
            failedRequestQueue.forEach(request => request.onFailure(err));
            failedRequestQueue = [];
            deslogar();
            window.location.href = routes.LOGIN.route;
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else if (!logado && !window.location.pathname.includes("Account") && !estaNoPublic()) {
        deslogar();
        window.location.href = routes.LOGIN.route;
      }

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: jwt => {
            originalRequest.headers.Authorization = `${jwt}`;

            resolve(Axios(originalRequest));
          },
          onFailure: err => {
            reject(err);
          },
        });
      });
    } catch (refreshError) {
      console.error("Erro ao renovar o token:", refreshError);
    }
  }

  return Promise.reject(error);
};

function manipularResponseRefreshToken(originalRequest, jwt, success) {
  switch (success) {
    case true:
      originalRequest.headers.Authorization = `${jwt}`;

      failedRequestQueue.forEach(request => request.onSuccess(jwt));
      failedRequestQueue = [];
      break;
    default:
      deslogar();
      window.location.href = routes.LOGIN.route;
      break;
  }
}
