import "./ExactIfraStytes/index.css";
/* eslint-disable spaced-comment */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable func-names */

function ExactAjaxDiagnostics(options = { single: false }) {
  const isSingle = !!options && options.single;
  const toastSuccess = "success";
  //private method alert
  const alert = function(message, dismissable, css_class, containerId) {
    if (isSingle && document.querySelectorAll(`#${containerId} > div`).length >= 1) {
      document.getElementById(containerId).innerHTML = "";
    }
    const divAlert = document.createElement("div");
    const divABootstrap = document.createElement("div");
    divABootstrap.className = "bootstrap";

    let classes;
    if (dismissable) {
      classes = `alert alert-${css_class} alert-dismissable fade in`;
    } else {
      classes = `alert alert-${css_class} fade in`;
    }

    if (css_class === toastSuccess) {
      removerToast(divAlert);
    }
    divAlert.className = classes;

    if (message) {
      const span = document.createElement("span");
      span.innerHTML = message;
      divAlert.appendChild(span);
    }

    if (dismissable) {
      const buttonDismiss = document.createElement("button");
      buttonDismiss.type = "button";
      buttonDismiss.className = "close";
      buttonDismiss.setAttribute("data-dismiss", "alert");
      buttonDismiss.innerHTML = "&times;";
      buttonDismiss.addEventListener("click", () => {
        divAlert.remove();
      });

      divAlert.appendChild(buttonDismiss);
    }

    const alertsContainer = document.getElementById(containerId);

    try {
      divABootstrap.appendChild(divAlert);
      alertsContainer.insertBefore(divABootstrap, alertsContainer.firstChild);
    } catch (err) {
      console.log(err);
    }
  };

  (this.Error = function(message) {
    if (!message) {
      message = "Não foi possível completar esta operação. Tente novamente mais tarde.";
      console.log(message);
    }

    const is_chrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    if (is_chrome && !navigator.onLine) {
      message = "Você está offline.";
    }

    document.getElementById("mensagemModalErro").innerHTML = message;
    document.getElementById("modalErroShared").style.display = "block";
  }),
    (this.ModalError = function(message) {
      document.getElementById("mensagemModalErro").innerHTML = message;
      document.getElementById("modalErroShared").style.display = "block";
    }),
    (this.ModalSucess = function(message) {
      document.getElementById("mensagemModalSucesso").innerHTML = message;
      document.getElementById("modalSucessoShared").style.display = "block";
    }),
    (this.ModalConfirm = function(message, buttonText, buttonFunction) {
      document.getElementById("mensagemModalConfirmarShared").innerHTML = message;
      document.getElementById("botaoConfirmar").addEventListener("click", buttonFunction);
      document.getElementById("modalConfirmarShared").style.display = "block";
    }),
    (this.AlertSuccess = function(message, dismissable, containerId = "alertsContainer") {
      alert(message, dismissable, "success", containerId);
    }),
    (this.AlertInfo = function(message, dismissable, containerId = "alertsContainer", autoHide = 0) {
      alert(message, dismissable, "info", containerId, autoHide);
    }),
    (this.AlertWarning = function(message, dismissable, containerId = "alertsContainer", autoHide = 0) {
      alert(message, dismissable, "warning", containerId, autoHide);
    }),
    (this.AlertDanger = function(message, dismissable, containerId = "alertsContainer", autoHide = 0) {
      alert(message, dismissable, "danger", containerId, autoHide);
    });
}

function removerToast(divAlert) {
  setTimeout(() => {
    divAlert.style.display = "none";
  }, 12000);
}
const AjaxDiagnostics = new ExactAjaxDiagnostics();
const AjaxDiagnosticsSingle = new ExactAjaxDiagnostics({ single: true });

export { AjaxDiagnostics, AjaxDiagnosticsSingle };
