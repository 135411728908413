import socket from "../../../websocket/socket";
import { setLocale as setLocaleAction, configureMoment } from "../../../store/reducers/locale/localeActions";
import { buscarLogado as buscarLogadoAction } from "../../../store/reducers/usuario/usuarioActions";
import { buscarLista as buscarListaVariaveisAction } from "../../../store/reducers/variaveisAmbiente/variaveisAmbienteActions";
import store from "../../../store/store";
import { addNoScriptGoogleManager, loadGoogleTagManager } from "./loadGoogleTagManager";
import { initializeGoogleAnalytics } from "./initializeGoogleAnalytics";
import { addDataLayer } from "./addDataLayer";
import { addPesquisaQuestionario } from "./addPesquisaQuestionario";
import { addNPS } from "./addNPS";
import { loadMonitorarUrls } from "./loadMonitorarUrls";
import { loadMagicWriteScriptAgenteMW } from "./addMagicWriteAgent";
import { definirPerfilUsuarioStartUp } from "./../helperContantes/perfilHelper";
import localStorageManager from "../storage";
import { INFOS_USUARIO } from "../storage/constantesStorage";

const Startup = async () => {
  const usaBroadcastWebphoneOpen = localStorageManager.getItem(INFOS_USUARIO)?.featureToggles
    ?.rolloutBroadcastOpenWebphone;

  if (usaBroadcastWebphoneOpen) {
    import("../exactWebPhone")
      .then(({ ExactWebPhone }) => {
        const webPhoneBroadcast = ExactWebPhone().getBroadcastChannel();
        webPhoneBroadcast.postMessage({ type: "ExactWebPhone.NewWindowsSpotterOpen" });
      })
      .catch(error => {
        console.error("Falha ao carregar o módulo ExactWebPhone:", error);
      });
  }

  const { dispatch } = store;

  const buscarLogado = () => dispatch(buscarLogadoAction());
  const buscarVariaveisAmbiente = () => dispatch(buscarListaVariaveisAction());
  const setLocale = locale => dispatch(setLocaleAction(locale));

  const [usuarioLogado, variaveisAmbiente] = await Promise.all([buscarLogado(), buscarVariaveisAmbiente()]);

  socket
    .configure({
      url: variaveisAmbiente.urlExactSignalR,
      hub: "ExactSignalRHub",
      maxReconnecAttempts: 5,
      reconnectTimeout: 6000,
      state: {
        id: usuarioLogado.id,
        isGerente: usuarioLogado.roles.some(role => role === "Gerente"),
        empresaClienteId: usuarioLogado.empresaClienteId,
        identificadorUsuario: usuarioLogado.canalComunicacaoWebsocket,
        role: usuarioLogado.tipoPerfil,
      },
      usaAzureSignalR: usuarioLogado.usaAzureSignalR,
    })
    .connect();

  await setLocale(usuarioLogado.idioma);
  configureMoment(usuarioLogado.idioma);

  if (usuarioLogado?.webAnalyticsToolHabilitado) {
    loadGoogleTagManager();
    initializeGoogleAnalytics(
      usuarioLogado?.nome,
      usuarioLogado?.empresaClienteId,
      usuarioLogado?.onboardingTags,
      definirPerfilUsuarioStartUp(usuarioLogado.tipoPerfil)
    );
    addNoScriptGoogleManager();
  }

  addDataLayer();

  if (usuarioLogado?.mostrarPesquisa) {
    addPesquisaQuestionario(usuarioLogado);
  }

  if (usuarioLogado?.coletarNps === true && usuarioLogado?.usuarioInterno === false) {
    addNPS(usuarioLogado);
  }

  if (
    usuarioLogado?.urlsMonitoradas !== null &&
    usuarioLogado?.urlsMonitoradas !== undefined &&
    usuarioLogado?.usuarioInterno === false
  ) {
    loadMonitorarUrls(usuarioLogado);
  }

  if (usuarioLogado?.empresaClienteId != null && usuarioLogado?.empresaClienteId === 14470) {
    loadMagicWriteScriptAgenteMW();
  }
};

export default Startup;
