/**
 *  Functional Helpers
 */
import { curry } from "lodash";
import { compose } from "lodash/fp";
import { escapeRegExp } from "../string";

// Empty array definition to avoid some re-renders
export const EMPTY_ARRAY = Object.freeze([]);

const withEmptyArray = arr => (arr && arr.length === 0 ? EMPTY_ARRAY : arr);

export const map = curry((fn, arr) => arr.map(fn));

export const reduce = curry((fn, x, xs) => xs.reduce(fn, x));

// match :: RegExp -> String -> [a]
export const match = curry((regex, str) => str.match(regex));

// filter :: (a -> Bool) -> [a] -> [a]
export const filter = curry((fn, arr) => arr.filter(fn));

// find :: (a -> Bool) -> [a] -> a
export const find = curry((fn, arr) => arr.find(fn));

// isArrayAndFilled :: [a] -> bool
export const isArrayAndFilled = arr => Array.isArray(arr) && !!arr.length;

// selectPropsValues :: [String] -> Object -> [Object]
export const selectPropsValues = curry((props, obj) =>
  props.map(propName => {
    const [prop, propNested] = propName.split("."); // 1 level nesting
    return propNested ? obj[prop][propNested] : obj[prop];
  })
);

// prop :: String -> Object -> a
export const prop = curry((propName, obj) => obj[propName]);

export const log = (...args) => data => {
  // eslint-disable-next-line
  console.log.apply(null, args.concat([data]));
  return data;
};

export const maybeCallback = callback => (...params) =>
  callback && typeof callback === "function" && callback(...params);

// filterByTermInProps :: String -> [String] -> [Object]
export const filterByTermInProps = curry((term, props, objsArray) => {
  const sanitizedTerm = escapeRegExp(term.trim());
  const rgxTerm = new RegExp(sanitizedTerm, "gi");
  const matchTerm = str => str && str.match(rgxTerm);

  // findAnyPropThatMatches :: obj -> Bool
  const findAnyPropThatMatches = compose(
    find(matchTerm),
    selectPropsValues(props)
  );

  return compose(
    withEmptyArray,
    filter(findAnyPropThatMatches)
  )(objsArray);
});
