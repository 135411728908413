/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable spaced-comment */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/**
 * ExactWebPhone exposes an API to
 * read/write shared cached state between
 * the main application and the WebPhone window;
 * Also, it handles WebPhone integration in a uniform
 * way.
 */

import axios from "axios";
import { defineMessage } from "react-intl";
import { AjaxBlackout, CacheState, AjaxDiagnosticsSingle } from "../";
import { CachedRequests } from "../utils/cachedRequests";
import { objectKeysToCamelCase } from "../utils/string";
import localStorageManager from "./storage";
import { INFOS_USUARIO } from "./storage/constantesStorage";

export const ExactWebPhone = function() {
  //================================================================================
  // Constants
  //================================================================================
  const usaBroadcastWebphoneOpen = localStorageManager.getItem(INFOS_USUARIO)?.featureToggles
    ?.rolloutBroadcastOpenWebphone;

  const _CACHE_KEY = "Spotter_ExactWebPhone_Defaut";
  const _POPUP_WINDOW_NAME = "ExactSalesSpotterWebPhone";
  const _BROADCAST_CHANNEL = "ExactWebPhone_BroadcastChannel";
  const PARCEIROS = {
    totalVoice: 2,
    flex: 5,
    vip: 9,
  };

  const ERROS = {
    empresaBloqueadaLigacao: 1,
  };

  const _DEFAULT_FLAGS = {
    didInitialOpen: false,
    isEnabled: false,
    ...(usaBroadcastWebphoneOpen ? {} : { isOpen: false }),
  };

  const _DEFAULT_CONFIG = {
    iFrameSrc: "",
    urlCallPhone: "",
    urlStatusCheck: "",
    urlCallback: "",
    ligacaoAutorizada: true,
    user_id: null,
    orgId: null,
    parceiroWebPhone: null,
    porcentagemExibirAvaliacao: 0,
    minutosParaProximaAvaliacao: 0,
    horaUltimaAvaliacao: null,
  };

  const _DEFAULT_CACHE_STATE = {
    ..._DEFAULT_FLAGS,
    ..._DEFAULT_CONFIG,
  };
  const MENSAGENS_TELEFONIA = {
    mensagemCarregando: defineMessage({
      defaultMessage: "Carregando...",
    }).defaultMessage,
    mensagemLigar: defineMessage({
      defaultMessage: "Ligar",
    }).defaultMessage,
    mensagemConfigChamada: defineMessage({
      defaultMessage:
        "Por favor, verifique junto ao gerente/suporte as configurações para realização de chamadas pelo software.",
    }).defaultMessage,
    mensagemLigacaoNaoPodeSerExecutada: defineMessage({
      defaultMessage: "A ligação não pode ser executada, pois uma outra chamada está em curso.",
    }).defaultMessage,
    mensagemSemTelefoneUsuario: defineMessage({
      defaultMessage:
        "Não encontramos o telefone do seu usuário. Para realizar ligações atualize o telefone no menu Sua conta.",
    }).defaultMessage,
    mensagemNumeroDestinoInvalido: defineMessage({
      defaultMessage: "Número de destino inválido. Por favor, verifique o número e tente novamente.",
    }).defaultMessage,
    mensagemNumeroDestinoIgualOrigem: defineMessage({
      defaultMessage:
        "A ligação não pode ser executada, o número de Destino precisa ser diferente do número de Origem.",
    }).defaultMessage,
    mensagemChamadaFinalizada: defineMessage({
      defaultMessage: "Chamada finalizada",
    }).defaultMessage,
    mensagemEncerrada: defineMessage({
      defaultMessage: "Encerrada",
    }).defaultMessage,
    mensagemChamandoDestino: defineMessage({
      defaultMessage: "Chamando Destino...",
    }).defaultMessage,
    mensagemChamandoOrigem: defineMessage({
      defaultMessage: "Chamando Origem...",
    }).defaultMessage,
    mensagemOcupado: defineMessage({
      defaultMessage: "Ocupado",
    }).defaultMessage,
    mensagemSemResposta: defineMessage({
      defaultMessage: "Sem resposta.",
    }).defaultMessage,
    mensagemErroRealizarChamada: defineMessage({
      defaultMessage: "Ocorreu uma falha ao realizar a chamada.",
    }).defaultMessage,
    mensagemIniciandoChamada: defineMessage({
      defaultMessage: "Iniciando chamada...",
    }).defaultMessage,
    mensagemWebPhoneOcupado: defineMessage({
      defaultMessage:
        "Parece que o WebPhone está ocupado ou sofreu um encerramento inesperado.\nCaso haja uma chamada em andamento, será finalizada ao completar esta ação.",
    }).defaultMessage,
    mensagemSemWebPhoneAberto: defineMessage({
      defaultMessage: "Não detectamos seu Webphone aberto. Clique no ícone de telefone para abri-lo.",
    }).defaultMessage,
    mensagemAtualizandoWebPhone: defineMessage({
      defaultMessage: "O seu Webphone está sendo atualizado, aguarde um momento e tente novamente.",
    }).defaultMessage,
    mensagemConectando: defineMessage({
      defaultMessage: "Conectando...",
    }).defaultMessage,
    mensagemPrincipal: defineMessage({
      defaultMessage: "Principal",
    }).defaultMessage,
    mensagemWebphoneBloqueado: defineMessage({
      defaultMessage: "Empresa bloqueada para ligação.",
    }).defaultMessage,
    mensagemWebphoneLigacaoParalelo: defineMessage({
      defaultMessage:
        "Você tem mais ligações em andamento do que licenças disponíveis. Consulte o suporte técnico ou solicite mais licenças.",
    }).defaultMessage,
  };
  const Axios = axios.create();
  Axios.interceptors.response.use(objectKeysToCamelCase);
  const cacheState = CacheState(_CACHE_KEY, _DEFAULT_CACHE_STATE);
  const broadcast = getBroadcastChannel();
  broadcast.onmessage = function handleWPbroadcastMessages(event) {
    var { data } = event;
    switch (data.type) {
      case "ExactWebPhone.callPhone.isConnectingCall":
        document.querySelectorAll(".txtLigarCommonClassSelector").forEach(element => {
          element.disabled = true;
        });
        setTimeout(() => {
          document.querySelectorAll(".txtLigarCommonClassSelector").forEach(element => {
            element.disabled = false;
          });
        }, 15000);
        break;
      case "ExactWebPhone.callPhone.connectingCallCompleted":
        document.querySelectorAll(".txtLigarCommonClassSelector").forEach(element => {
          element.disabled = false;
        });
        break;
      case "ExactWebPhone.WebphoneOpen":
        window.WINDOW_WEBPHONE_OPEN = data.payload;
        break;
      case "ExactWebPhone.NewWindowsSpotterOpen":
        if (window.WINDOW_WEBPHONE_OPEN) {
          broadcast.postMessage({
            type: "ExactWebPhone.WebphoneOpen",
            payload: window.WINDOW_WEBPHONE_OPEN,
          });
        }
        break;
      default:
    }
  };
  var success;
  var error;

  function controlarLigacaoEmCurso(status) {
    cacheState.set({
      ligacaoEmCurso: status,
    });
    if (!status) {
      document.getElementById("ajuste_ligacao_curso").style.height = "40px";
      document.getElementById("mensagem_ligacao_curso").style.display = "none";
    }
  }

  //================================================================================
  // Shared State Handlers
  //================================================================================
  function initState(config = _DEFAULT_CONFIG) {
    success = config.onSuccess || console.log;
    error = config.onError || console.log;
    cacheState.set({
      isEnabled: true,
      ...config,
      resultadoLigacaoDados: {
        leadId: "",
        nomeLead: "",
        ligacaoId: "",
      },
    });
  }

  function isEnabled() {
    return cacheState.get().isEnabled;
  }
  function iframeSrc() {
    return cacheState.get().iFrameSrc;
  }
  function tipoGravacaoWebphone() {
    return cacheState.get().tipoGravacaoWebphone;
  }

  function open() {
    if (isOpen()) {
      throw new Error(MENSAGENS_TELEFONIA.mensagemWebPhoneOcupado);
    }
    return forceOpen();
  }

  function forceOpen() {
    const keepId = Date.now();
    closeAllPopups(keepId);

    const wnd = window.open(`/webphone/discador`, _POPUP_WINDOW_NAME, "width=296,height=610");
    window.WINDOW_REF = wnd;

    if (!wnd) return statusError();

    wnd.$$ExactWebPhoneKeepId = keepId;

    if (!usaBroadcastWebphoneOpen) {
      cacheState.set({ isOpen: true });
    }

    return statusSuccess();
  }

  function bringToFrontWindow() {
    if (window.WINDOW_REF && !window.WINDOW_REF.closed) {
      window.WINDOW_REF.focus();
    }
  }

  function autoOpen() {
    if (!cacheState.get().didInitialOpen) {
      open();
      cacheState.set({ didInitialOpen: true });
    }
  }

  function closeAllPopups(keepId = "") {
    broadcast.postMessage({
      type: "ExactWebPhone.closeAllPopups",
      payload: { keepId },
    });
  }

  function isOpen() {
    return usaBroadcastWebphoneOpen ? window?.WINDOW_WEBPHONE_OPEN === true : cacheState.get().isOpen;
  }

  function clear() {
    if (usaBroadcastWebphoneOpen) {
      const keepId = Date.now();
      closeAllPopups(keepId);
    }

    return cacheState.clear();
  }

  function onLoad() {
    cacheState.set({
      isOpen: true,
    });
  }

  function onUnload() {
    const usuarioLogado = localStorageManager.getItem(INFOS_USUARIO);
    if (usuarioLogado) {
      cacheState.set({
        phoneNumber: "",
        leadId: "",
        isConnectingCall: false,
        ...(usaBroadcastWebphoneOpen ? {} : { isOpen: false }),
      });
    }
  }

  /**
   * Creates a Broadcast Channel that
   * allows communication between Spotter
   * browsing context.
   * Mainly used between Spotter pages and
   * the WebPhone pop-up window
   */
  function getBroadcastChannel() {
    return new BroadcastChannel(_BROADCAST_CHANNEL);
  }

  /**
   * It will start a phone call using the current default WebPhone.
   * If call start succeeds, it will send call parameters to Spotter server.
   * @param {String} phone
   * @param {Number} leadId
   */
  async function callPhone({ ddi, numero, leadId, nomeLead }) {
    try {
      if (!(await _beforeCallValidation(true, null))) {
        return;
      }

      if (cacheState.get().ultimaLigacaoTeste) {
        cacheState.set({
          ultimaLigacaoTeste: false,
        });

        setTimeout(async () => {
          await call(ddi, numero, leadId, nomeLead);
        }, 5000);
      } else {
        await call(ddi, numero, leadId, nomeLead);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function callJson(ddi, numero, leadId, nomeLead) {
    try {
      if (!(await _beforeCallValidation(true, null))) {
        return;
      }

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.isConnectingCall" });
      AjaxDiagnosticsSingle.AlertSuccess(MENSAGENS_TELEFONIA.mensagemIniciandoChamada, true);

      const response = await Axios.post(
        `${cacheState.get().urlInicioLigacao}/api/pipeline/Ligacao/IniciarPorWebphone`,
        {
          ddi_destination: _SomenteNumeros(ddi),
          call_destination: _SomenteNumeros(numero),
          lead_id: leadId,
          urlCallPhone: cacheState.get().urlCallPhone,
          callback_url: cacheState.get().callbackUrl,
          parceiro: cacheState.get().operadoraTelefonia,
          empresaClienteId: cacheState.get().empresaClienteId,
          usuarioId: cacheState.get().usuarioId,
        },
        {
          headers: {
            "token-exact": cacheState.get().tokenExact,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
      broadcast.postMessage({ type: "ExactWebPhone.callPhone.connectingCallCompleted" });

      if (cacheState.get().ligacoesParalelas > 0) {
        await axios.post("/api/pipeline/credencialLigacao/RegistrarLigacaoEmParalelo", null);
      }

      // success();

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.sendProtecaoDados", payload: response.data });
      cacheState.set({
        resultadoLigacaoDados: {
          leadId,
          nomeLead,
          ligacaoId: response.data.ligacaoId,
        },
      });

      if (!response.data.success) {
        if (response.data.tipoErro === ERROS.empresaBloqueadaLigacao) {
          AjaxDiagnosticsSingle.AlertDanger(MENSAGENS_TELEFONIA.mensagemWebphoneBloqueado, true);
        }
        AjaxDiagnosticsSingle.AlertDanger(response.data.message, true);
      }
    } catch (error) {
      console.error(error);
      // error(error);
      CachedRequests.invalidateUrl("/api/pipeline/credencialLigacao/buscar");
    }
  }

  async function call(ddi, numero, leadId, nomeLead) {
    if (cacheState.get().utilizarServicoIniciarLigacao) {
      await callJson(ddi, numero, leadId, nomeLead);
      return;
    }

    try {
      if (!(await _beforeCallValidation(true, null))) {
        return;
      }

      const response = await axios.post(
        `${cacheState.get().urlInicioLigacao}/api/pipeline/Ligacao/IniciarPorWebphone`,
        {
          ddi_destination: _SomenteNumeros(ddi),
          call_destination: _SomenteNumeros(numero),
          lead_id: leadId,
        }
      );

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.isConnectingCall" });
      AjaxDiagnosticsSingle.AlertSuccess(MENSAGENS_TELEFONIA.mensagemIniciandoChamada, true);

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
      broadcast.postMessage({ type: "ExactWebPhone.callPhone.connectingCallCompleted" });

      if (cacheState.get().ligacoesParalelas > 0) {
        await axios.post("/api/pipeline/credencialLigacao/RegistrarLigacaoEmParalelo", null);
      }

      if (response.data.ligacaoId !== null) {
        controlarLigacaoEmCurso(true);
      }

      success();

      broadcast.postMessage({ type: "ExactWebPhone.callPhone.sendProtecaoDados", payload: response.data });
      cacheState.set({
        resultadoLigacaoDados: {
          leadId,
          nomeLead,
          ligacaoId: response.data.ligacaoId,
        },
      });
    } catch (error) {
      console.error(error);
      error(error);
      CachedRequests.invalidateUrl("/api/pipeline/credencialLigacao/buscar");
    }
  }

  async function callPhoneTeste(parceiro) {
    try {
      if (!(await _beforeCallValidation(false, parceiro))) {
        return;
      }

      cacheState.set({
        ultimaLigacaoTeste: true,
      });

      let headers = {
        Authorization: cacheState.get().token,
      };

      let data = {};
      let proccessData = true;

      if (cacheState.get().utilizarServicoIniciarLigacao) {
        data = JSON.stringify({
          urlCallPhone: cacheState.get().urlCallPhone,
          callback_url: cacheState.get().callbackUrl,
          // eslint-disable-next-line radix
          parceiro: parseInt(parceiro),
          empresaClienteId: cacheState.get().empresaClienteId,
          usuarioId: cacheState.get().usuarioId,
          call_destination: cacheState.get().call_destination,
        });

        proccessData = false;
        headers = {
          "token-exact": cacheState.get().tokenExact,
        };
      }

      setTimeout(() => {
        broadcast.postMessage({ type: "ExactWebPhone.callPhone.isConnectingCall" });
        AjaxDiagnosticsSingle.AlertSuccess(MENSAGENS_TELEFONIA.mensagemIniciandoChamada, true);

        Axios({
          method: "post",
          url: `${cacheState.get().urlInicioLigacao}/api/pipeline/Ligacao/IniciarLigacaoTeste?parceiro=${parceiro}`,
          headers,
          data,
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.error(error);
            CachedRequests.invalidateUrl("/api/pipeline/credencialLigacao/buscar");
          })
          .then(() => {});
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  }

  const CallActions = {
    async listenToCall(callInfo) {
      await _doCallAction(callInfo, "/api/pipeline/Ligacao/interceptar?acao=1");
    },

    async talkToCaller(callInfo) {
      await _doCallAction(callInfo, "/api/pipeline/Ligacao/interceptar?acao=2");
    },

    async joinCall(callInfo) {
      await _doCallAction(callInfo, "/api/pipeline/Ligacao/interceptar?acao=3");
    },
  };

  async function reloadWebphoneData(validarLigacoesParalelas = false) {
    let wasValid = true;

    const webPhoneData = await axios
      .get(`/api/pipeline/credencialLigacao/buscar?buscarLigacoesParalelas=${validarLigacoesParalelas}`)
      .then(response => response.data)
      .catch(error => {
        console.error(error);
      });
    const {
      url,
      parceiroWebPhone,
      habilitarColetaResultados,
      urlInicioLigacao,
      utilizarServicoIniciarLigacao,
      tokenExact,
      operadoraTelefonia,
      urlCallPhone,
      // eslint-disable-next-line no-useless-rename
      callbackUrl: callback_url,
      empresaClienteId,
      usuarioId,
      medirLatencia,
      tempoMedirLatencia,
      ligacoesParalelas,
      qtdLigacoesEmParalelo,
    } = webPhoneData;

    const isIFrameOpen = usaBroadcastWebphoneOpen ? isOpen() : cacheState.get().isOpen;

    if (isIFrameOpen && cacheState.get().ligacaoTeste) {
      _restartIFrame(url);
    }

    let bloquearLigacoesParalelas = false;
    if (validarLigacoesParalelas && ligacoesParalelas > 0 && qtdLigacoesEmParalelo >= ligacoesParalelas) {
      wasValid = false;
      bloquearLigacoesParalelas = true;
    }

    cacheState.set({
      habilitarColetaResultados,
      urlInicioLigacao,
      utilizarServicoIniciarLigacao,
      tokenExact,
      operadoraTelefonia,
      urlCallPhone,
      callbackUrl: callback_url,
      empresaClienteId,
      usuarioId,
      ligacaoTeste: false,
      medirLatencia,
      tempoMedirLatencia,
      ligacoesParalelas,
      qtdLigacoesEmParalelo,
      bloquearLigacoesParalelas,
    });

    if (!_isValidIFrameLoaded(url)) {
      CachedRequests.invalidateUrl("/api/pipeline/credencialLigacao/buscar");
      cacheState.set({
        iFrameSrc: url,
        parceiroWebPhone,
        habilitarColetaResultados,
        ligacaoTeste: false,
      });
      wasValid = false;
    }

    if (medirLatencia) {
      medirLatencia();
    }
    return wasValid;
  }

  async function reloadWebphoneTesteOperador(operador) {
    let wasValid = true;
    const webPhoneData = await axios({
      method: "get",
      url: "/api/pipeline/credencialLigacao/BuscarParaTesteLigacao",
      params: {
        parceiro: operador,
      },
    })
      .then(response => response.data)
      .catch(error => {
        console.error(error);
      });

    const {
      url,
      habilitarColetaResultados,
      urlInicioLigacao,
      utilizarServicoIniciarLigacao,
      tokenExact,
      urlCallPhone,
      callback_url,
      empresaClienteId,
      usuarioId,
      call_destination,
    } = webPhoneData;

    cacheState.set({
      habilitarColetaResultados,
      urlInicioLigacao,
      ligacaoTeste: true,
      utilizarServicoIniciarLigacao,
      tokenExact,
      urlCallPhone,
      callbackUrl: callback_url,
      empresaClienteId,
      usuarioId,
      call_destination,
    });

    if (!_isValidIFrameLoaded(url)) {
      CachedRequests.invalidateUrl("/api/pipeline/credencialLigacao/buscar");

      cacheState.set({
        iFrameSrc: url,
        parceiroWebPhone: operador,
        ligacaoTeste: true,
      });

      const isIFrameOpen = usaBroadcastWebphoneOpen ? isOpen() : cacheState.get().isOpen;

      if (isIFrameOpen) {
        _restartIFrame(url);
      }

      wasValid = false;
    }

    return wasValid;
  }

  function getParceiro() {
    const parceiro = cacheState.get().parceiroWebPhone;
    return parceiro > 0 ? parceiro : null;
  }

  function onHangUpCall() {
    const parceiro = getParceiro();
    // parceiro que implementaram o evento `hangup_call` até o momento
    if ((+parceiro === PARCEIROS.totalVoice || +parceiro === PARCEIROS.vip) && !cacheState.get().ligacaoTeste) {
      _sinalizarResultadoLigacao();
    }
  }

  /**
   * Dispara o evento para a modal de Resultado de Ligacao no SPA
   */
  function _sinalizarResultadoLigacao() {
    const { habilitarColetaResultados, user_id, resultadoLigacaoDados } = cacheState.get();
    let deveMostrarQualidadeLigacao = true;

    if (!habilitarColetaResultados) {
      deveMostrarQualidadeLigacao = _getDeveMostrarQualidadeLigacao();
    }

    if (!deveMostrarQualidadeLigacao && !habilitarColetaResultados) {
      return;
    }
    const payload = {
      leadId: resultadoLigacaoDados?.leadId || "",
      callId: resultadoLigacaoDados?.ligacaoId || "",
      nomeLead: resultadoLigacaoDados?.nomeLead || "",
      resultadoLigacaoHabilitado: habilitarColetaResultados,
      usuarioId: user_id,
    };
    controlarLigacaoEmCurso(false);
    broadcast.postMessage({ type: "ExactWebPhone.sinalizarResultadoLigacao", payload });
  }

  function registrarHoraUltimaAvaliacao(horaUltimaAvaliacao = new Date()) {
    cacheState.set({
      horaUltimaAvaliacao,
    });
  }

  function _getDeveMostrarQualidadeLigacao() {
    const { porcentagemExibirAvaliacao, minutosParaProximaAvaliacao, horaUltimaAvaliacao } = cacheState.get();
    const probabilidade = porcentagemExibirAvaliacao || 0;

    if (probabilidade <= 0) return false;

    const intervaloUltimaAvaliacaoMinutos = horaUltimaAvaliacao
      ? (new Date() - new Date(horaUltimaAvaliacao)) / 60000
      : Infinity;

    if (intervaloUltimaAvaliacaoMinutos >= minutosParaProximaAvaliacao) {
      return Math.ceil(Math.random() * 100) <= probabilidade;
    }

    return false;
  }

  async function _validateWebphoneStatus() {
    let isValid = true;

    if (!isOpen()) {
      isValid = false;
      AjaxDiagnosticsSingle.AlertDanger(MENSAGENS_TELEFONIA.mensagemSemWebPhoneAberto, true);
    }

    return isValid;
  }

  function _isValidIFrameLoaded(url) {
    const { iFrameSrc } = cacheState.get();
    return url === iFrameSrc;
  }

  function _restartIFrame(url) {
    broadcast.postMessage({ type: "ExactWebPhone.window.restart", payload: url });
  }

  function _SomenteNumeros(valor = "") {
    if (typeof valor !== "string") {
      valor = `${valor}`;
    }
    return valor.replace(/\D*/gi, "");
  }

  function statusResult(success) {
    return function({ message, detail } = { message: "", detail: null }) {
      return {
        message,
        detail,
        success,
      };
    };
  }

  /**
   * Helper to perform remote call actions
   * @param {object} callInfo
   * @param {string} urlName key name in urlsPainel
   */
  async function _doCallAction(callInfo, url) {
    if (!(await _beforeCallValidation(true, null))) {
      return;
    }

    AjaxDiagnosticsSingle.AlertSuccess(MENSAGENS_TELEFONIA.mensagemConectando, true);

    axios({
      method: "post",
      url,
      data: {
        id: callInfo.ligacao.id,
        usuarioId: callInfo.usuario && callInfo.usuario.id,
        leadId: callInfo.ligacao.leadId,
      },
    })
      .then(response => {
        // success(response);
      })
      .catch(error => {
        // error(error);
      });
  }

  async function _beforeCallValidation(executarReload, parceiro) {
    console.log("_beforeCallValidation");
    AjaxBlackout.Show();
    broadcast.postMessage({ type: "ExactWebPhone.callPhone.beforeSend" });

    if (executarReload) {
      if (!(await reloadWebphoneData(true))) {
        if (cacheState.get().bloquearLigacoesParalelas) {
          AjaxDiagnosticsSingle.AlertWarning(MENSAGENS_TELEFONIA.mensagemWebphoneLigacaoParalelo, true);
          AjaxBlackout.Hide();
          broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
          return false;
        } else {
          AjaxDiagnosticsSingle.AlertWarning(MENSAGENS_TELEFONIA.mensagemAtualizandoWebPhone, true);
          AjaxBlackout.Hide();
          broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
          return false;
        }
      }
    } else {
      await reloadWebphoneTesteOperador(parceiro);
    }

    if (!(await _validateWebphoneStatus())) {
      AjaxBlackout.Hide();
      broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
      return false;
    }

    AjaxBlackout.Hide();
    broadcast.postMessage({ type: "ExactWebPhone.callPhone.complete" });
    return true;
  }

  async function medirLatencia() {
    let url = cacheState.get().iFrameSrc;
    const parceiro = cacheState.get().parceiroWebPhone;
    const { tempoMedirLatencia } = cacheState.get();
    const sucesso = 4;

    if (parceiro === PARCEIROS.totalVoice) {
      const objetoUrl = new URL(url);
      url = objetoUrl.origin + objetoUrl.pathname;
    }

    let tentativas = 0;
    const maxTentativas = 3;

    function medir() {
      if (tentativas >= maxTentativas) {
        clearInterval(intervalId);
        return;
      }

      const inicio = new Date().getTime();

      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);

      xhr.onload = function() {
        if (xhr.readyState === sucesso) {
          const final = new Date().getTime();
          const latencia = final - inicio;

          if (latencia >= 150 && latencia < 300) {
            document.getElementById("iconeRede").style = "color: yellow;";
          } else if (latencia >= 300) {
            document.getElementById("iconeRede").style = "color: red;";
          } else {
            document.getElementById("iconeRede").style = "color: green;";
          }
        }
      };

      xhr.onerror = function() {
        tentativas++;
      };

      xhr.send();
    }
    await medir();
    // eslint-disable-next-line vars-on-top, no-var
    var intervalId = setInterval(medir, tempoMedirLatencia);
  }

  var statusError = statusResult(false);
  var statusSuccess = statusResult(true);

  //================================================================================
  // Exposes Public API
  //================================================================================
  return {
    initState,
    isEnabled,
    isOpen,
    CallActions,
    clear,
    open,
    forceOpen,
    callPhone,
    callPhoneTeste,
    autoOpen,
    onLoad,
    onUnload,
    getBroadcastChannel,
    closeAllPopups,
    onHangUpCall,
    getParceiro,
    reloadWebphoneData,
    reloadWebphoneTesteOperador,
    registrarHoraUltimaAvaliacao,
    medirLatencia,
    iframeSrc,
    tipoGravacaoWebphone,
    bringToFrontWindow,
  };
};
