import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { AppWrapper } from "../..";
import ConnectedIntlProvider from "../../containers/ConnectedIntlProvider";
import DialogConfirm from "./DialogConfirm";
import store from "../../../store/store";

const createDialogConfirm = props => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedIntlProvider>
        <AppWrapper>
          <DialogConfirm
            {...props}
            unmount={() => ReactDOM.unmountComponentAtNode(document.getElementById("dialogConfirm"))}
          />
        </AppWrapper>
      </ConnectedIntlProvider>
    </Provider>,
    document.getElementById("dialogConfirm")
  );
};

export default createDialogConfirm;
