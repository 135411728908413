import createSnackBar from "./createSnackbar";

export default function createSnackAPIException(err) {
  let message;
  try {
    message = err.response?.data?.ExceptionMessage || err.message;
  } catch (e) {
    message = "Ocorreu um erro.";
    // eslint-disable-next-line
    console.error(e);
  }
  createSnackBar(message);
}
