import af from "./flags/af.svg";
import al from "./flags/al.svg";
import dz from "./flags/dz.svg";
import as from "./flags/as.svg";
import ad from "./flags/ad.svg";
import ao from "./flags/ao.svg";
import ai from "./flags/ai.svg";
import ag from "./flags/ag.svg";
import ar from "./flags/ar.svg";
import am from "./flags/am.svg";
import aw from "./flags/aw.svg";
import au from "./flags/au.svg";
import at from "./flags/at.svg";
import az from "./flags/az.svg";
import bs from "./flags/bs.svg";
import bh from "./flags/bh.svg";
import bd from "./flags/bd.svg";
import bb from "./flags/bb.svg";
import by from "./flags/by.svg";
import be from "./flags/be.svg";
import bz from "./flags/bz.svg";
import bj from "./flags/bj.svg";
import bm from "./flags/bm.svg";
import bt from "./flags/bt.svg";
import bo from "./flags/bo.svg";
import ba from "./flags/ba.svg";
import bw from "./flags/bw.svg";
import br from "./flags/br.svg";
import io from "./flags/io.svg";
import vi from "./flags/vi.svg";
import bg from "./flags/bg.svg";
import bf from "./flags/bf.svg";
import bi from "./flags/bi.svg";
import kh from "./flags/kh.svg";
import cm from "./flags/cm.svg";
import cv from "./flags/cv.svg";
import ky from "./flags/ky.svg";
import cf from "./flags/cf.svg";
import td from "./flags/td.svg";
import cl from "./flags/cl.svg";
import cn from "./flags/cn.svg";
import co from "./flags/co.svg";
import km from "./flags/km.svg";
import cg from "./flags/cg.svg";
import cd from "./flags/cd.svg";
import ck from "./flags/ck.svg";
import cr from "./flags/cr.svg";
import hr from "./flags/hr.svg";
import cu from "./flags/cu.svg";
import cw from "./flags/cw.svg";
import cy from "./flags/cy.svg";
import cz from "./flags/cz.svg";
import dk from "./flags/dk.svg";
import dj from "./flags/dj.svg";
import dm from "./flags/dm.svg";
import dof from "./flags/do.svg";
import ec from "./flags/ec.svg";
import eg from "./flags/eg.svg";
import sv from "./flags/sv.svg";
import gq from "./flags/gq.svg";
import er from "./flags/er.svg";
import ee from "./flags/ee.svg";
import et from "./flags/et.svg";
import fk from "./flags/fk.svg";
import fo from "./flags/fo.svg";
import fj from "./flags/fj.svg";
import fi from "./flags/fi.svg";
import fr from "./flags/fr.svg";
import pf from "./flags/pf.svg";
import ga from "./flags/ga.svg";
import gm from "./flags/gm.svg";
import ge from "./flags/ge.svg";
import de from "./flags/de.svg";
import gh from "./flags/gh.svg";
import gi from "./flags/gi.svg";
import gr from "./flags/gr.svg";
import gl from "./flags/gl.svg";
import gd from "./flags/gd.svg";
import gu from "./flags/gu.svg";
import gt from "./flags/gt.svg";
import gn from "./flags/gn.svg";
import gw from "./flags/gw.svg";
import ht from "./flags/ht.svg";
import hn from "./flags/hn.svg";
import hk from "./flags/hk.svg";
import hu from "./flags/hu.svg";
import is from "./flags/is.svg";
import inf from "./flags/in.svg";
import id from "./flags/id.svg";
import ci from "./flags/ci.svg";
import ir from "./flags/ir.svg";
import iq from "./flags/iq.svg";
import ie from "./flags/ie.svg";
import il from "./flags/il.svg";
import it from "./flags/it.svg";
import jm from "./flags/jm.svg";
import jp from "./flags/jp.svg";
import jo from "./flags/jo.svg";
import kz from "./flags/kz.svg";
import ke from "./flags/ke.svg";
import ki from "./flags/ki.svg";
import kw from "./flags/kw.svg";
import kg from "./flags/kg.svg";
import la from "./flags/la.svg";
import lv from "./flags/lv.svg";
import lb from "./flags/lb.svg";
import ls from "./flags/ls.svg";
import lr from "./flags/lr.svg";
import ly from "./flags/ly.svg";
import li from "./flags/li.svg";
import lt from "./flags/lt.svg";
import lu from "./flags/lu.svg";
import mo from "./flags/mo.svg";
import mk from "./flags/mk.svg";
import mg from "./flags/mg.svg";
import mw from "./flags/mw.svg";
import my from "./flags/my.svg";
import mv from "./flags/mv.svg";
import ml from "./flags/ml.svg";
import mt from "./flags/mt.svg";
import mh from "./flags/mh.svg";
import mq from "./flags/mq.svg";
import mr from "./flags/mr.svg";
import mu from "./flags/mu.svg";
import mx from "./flags/mx.svg";
import fm from "./flags/fm.svg";
import md from "./flags/md.svg";
import mc from "./flags/mc.svg";
import mn from "./flags/mn.svg";
import me from "./flags/me.svg";
import ms from "./flags/ms.svg";
import ma from "./flags/ma.svg";
import mz from "./flags/mz.svg";
import mm from "./flags/mm.svg";
import na from "./flags/na.svg";
import nr from "./flags/nr.svg";
import np from "./flags/np.svg";
import nl from "./flags/nl.svg";
import ni from "./flags/ni.svg";
import ne from "./flags/ne.svg";
import ng from "./flags/ng.svg";
import nu from "./flags/nu.svg";
import nf from "./flags/nf.svg";
import kp from "./flags/kp.svg";
import mp from "./flags/mp.svg";
import no from "./flags/no.svg";
import om from "./flags/om.svg";
import pk from "./flags/pk.svg";
import pw from "./flags/pw.svg";
import ps from "./flags/ps.svg";
import pa from "./flags/pa.svg";
import pg from "./flags/pg.svg";
import py from "./flags/py.svg";
import pe from "./flags/pe.svg";
import ph from "./flags/ph.svg";
import pl from "./flags/pl.svg";
import pt from "./flags/pt.svg";
import pr from "./flags/pr.svg";
import qa from "./flags/qa.svg";
import ro from "./flags/ro.svg";
import ru from "./flags/ru.svg";
import rw from "./flags/rw.svg";
import kn from "./flags/kn.svg";
import lc from "./flags/lc.svg";
import ws from "./flags/ws.svg";
import sm from "./flags/sm.svg";
import st from "./flags/st.svg";
import sa from "./flags/sa.svg";
import sn from "./flags/sn.svg";
import rs from "./flags/rs.svg";
import sc from "./flags/sc.svg";
import sl from "./flags/sl.svg";
import sg from "./flags/sg.svg";
import sx from "./flags/sx.svg";
import sk from "./flags/sk.svg";
import si from "./flags/si.svg";
import sb from "./flags/sb.svg";
import so from "./flags/so.svg";
import za from "./flags/za.svg";
import kr from "./flags/kr.svg";
import ss from "./flags/ss.svg";
import es from "./flags/es.svg";
import lk from "./flags/lk.svg";
import sd from "./flags/sd.svg";
import sr from "./flags/sr.svg";
import sz from "./flags/sz.svg";
import se from "./flags/se.svg";
import ch from "./flags/ch.svg";
import sy from "./flags/sy.svg";
import tw from "./flags/tw.svg";
import tj from "./flags/tj.svg";
import tz from "./flags/tz.svg";
import th from "./flags/th.svg";
import tg from "./flags/tg.svg";
import tk from "./flags/tk.svg";
import to from "./flags/to.svg";
import tt from "./flags/tt.svg";
import tn from "./flags/tn.svg";
import tr from "./flags/tr.svg";
import tm from "./flags/tm.svg";
import tc from "./flags/tc.svg";
import tv from "./flags/tv.svg";
import ug from "./flags/ug.svg";
import ua from "./flags/ua.svg";
import ae from "./flags/ae.svg";
import gb from "./flags/gb.svg";
import us from "./flags/us.svg";
import uy from "./flags/uy.svg";
import uz from "./flags/uz.svg";
import vu from "./flags/vu.svg";
import ve from "./flags/ve.svg";
import vn from "./flags/vn.svg";
import ye from "./flags/ye.svg";
import zm from "./flags/zm.svg";
import zw from "./flags/zw.svg";
import gy from "./flags/gy.svg";
import gf from "./flags/gf.svg";
import re from "./flags/re.svg";
import gp from "./flags/gp.svg";
import nz from "./flags/nz.svg";

export const countries = {
  55: { flag: br, name: "Brazil", alpha2Code: "BR", callingCode: "55" },
  1: { flag: us, name: "United States of America", alpha2Code: "US", callingCode: "1" },
  7: { flag: ru, name: "Russian Federation", alpha2Code: "RU", callingCode: "7" },
  20: { flag: eg, name: "Egypt", alpha2Code: "EG", callingCode: "20" },
  27: { flag: za, name: "South Africa", alpha2Code: "ZA", callingCode: "27" },
  30: { flag: gr, name: "Greece", alpha2Code: "GR", callingCode: "30" },
  31: { flag: nl, name: "Netherlands", alpha2Code: "NL", callingCode: "31" },
  32: { flag: be, name: "Belgium", alpha2Code: "BE", callingCode: "32" },
  33: { flag: fr, name: "France", alpha2Code: "FR", callingCode: "33" },
  34: { flag: es, name: "Spain", alpha2Code: "ES", callingCode: "34" },
  36: { flag: hu, name: "Hungary", alpha2Code: "HU", callingCode: "36" },
  39: { flag: it, name: "Italy", alpha2Code: "IT", callingCode: "39" },
  40: { flag: ro, name: "Romania", alpha2Code: "RO", callingCode: "40" },
  41: { flag: ch, name: "Switzerland", alpha2Code: "CH", callingCode: "41" },
  43: { flag: at, name: "Austria", alpha2Code: "AT", callingCode: "43" },
  44: { flag: gb, name: "United Kingdom of Great Britain", alpha2Code: "GB", callingCode: "44" },
  45: { flag: dk, name: "Denmark", alpha2Code: "DK", callingCode: "45" },
  46: { flag: se, name: "Sweden", alpha2Code: "SE", callingCode: "46" },
  47: { flag: no, name: "Norway", alpha2Code: "NO", callingCode: "47" },
  48: { flag: pl, name: "Poland", alpha2Code: "PL", callingCode: "48" },
  49: { flag: de, name: "Germany", alpha2Code: "DE", callingCode: "49" },
  51: { flag: pe, name: "Peru", alpha2Code: "PE", callingCode: "51" },
  52: { flag: mx, name: "Mexico", alpha2Code: "MX", callingCode: "52" },
  53: { flag: cu, name: "Cuba", alpha2Code: "CU", callingCode: "53" },
  54: { flag: ar, name: "Argentina", alpha2Code: "AR", callingCode: "54" },
  56: { flag: cl, name: "Chile", alpha2Code: "CL", callingCode: "56" },
  57: { flag: co, name: "Colombia", alpha2Code: "CO", callingCode: "57" },
  58: { flag: ve, name: "Venezuela (Bolivarian Republic of)", alpha2Code: "VE", callingCode: "58" },
  60: { flag: my, name: "Malaysia", alpha2Code: "MY", callingCode: "60" },
  61: { flag: au, name: "Australia", alpha2Code: "AU", callingCode: "61" },
  62: { flag: id, name: "Indonesia", alpha2Code: "ID", callingCode: "62" },
  63: { flag: ph, name: "Philippines", alpha2Code: "PH", callingCode: "63" },
  64: { flag: nz, name: "New Zealand", alpha2Code: "NZ", callingCode: "64" },
  65: { flag: sg, name: "Singapore", alpha2Code: "SG", callingCode: "65" },
  66: { flag: th, name: "Thailand", alpha2Code: "TH", callingCode: "66" },
  76: { flag: kz, name: "Kazakhstan", alpha2Code: "KZ", callingCode: "76" },
  81: { flag: jp, name: "Japan", alpha2Code: "JP", callingCode: "81" },
  82: { flag: kr, name: "Korea (Republic of)", alpha2Code: "KR", callingCode: "82" },
  84: { flag: vn, name: "Viet Nam", alpha2Code: "VN", callingCode: "84" },
  86: { flag: cn, name: "China", alpha2Code: "CN", callingCode: "86" },
  90: { flag: tr, name: "Turkey", alpha2Code: "TR", callingCode: "90" },
  91: { flag: inf, name: "India", alpha2Code: "IN", callingCode: "91" },
  92: { flag: pk, name: "Pakistan", alpha2Code: "PK", callingCode: "92" },
  93: { flag: af, name: "Afghanistan", alpha2Code: "AF", callingCode: "93" },
  94: { flag: lk, name: "Sri Lanka", alpha2Code: "LK", callingCode: "94" },
  95: { flag: mm, name: "Myanmar", alpha2Code: "MM", callingCode: "95" },
  98: { flag: ir, name: "Iran (Islamic Republic of)", alpha2Code: "IR", callingCode: "98" },
  211: { flag: ss, name: "South Sudan", alpha2Code: "SS", callingCode: "211" },
  212: { flag: ma, name: "Morocco", alpha2Code: "MA", callingCode: "212" },
  213: { flag: dz, name: "Algeria", alpha2Code: "DZ", callingCode: "213" },
  216: { flag: tn, name: "Tunisia", alpha2Code: "TN", callingCode: "216" },
  218: { flag: ly, name: "Libya", alpha2Code: "LY", callingCode: "218" },
  220: { flag: gm, name: "Gambia", alpha2Code: "GM", callingCode: "220" },
  221: { flag: sn, name: "Senegal", alpha2Code: "SN", callingCode: "221" },
  222: { flag: mr, name: "Mauritania", alpha2Code: "MR", callingCode: "222" },
  223: { flag: ml, name: "Mali", alpha2Code: "ML", callingCode: "223" },
  224: { flag: gn, name: "Guinea", alpha2Code: "GN", callingCode: "224" },
  225: { flag: ci, name: "Côte d'Ivoire", alpha2Code: "CI", callingCode: "225" },
  226: { flag: bf, name: "Burkina Faso", alpha2Code: "BF", callingCode: "226" },
  227: { flag: ne, name: "Niger", alpha2Code: "NE", callingCode: "227" },
  228: { flag: tg, name: "Togo", alpha2Code: "TG", callingCode: "228" },
  229: { flag: bj, name: "Benin", alpha2Code: "BJ", callingCode: "229" },
  230: { flag: mu, name: "Mauritius", alpha2Code: "MU", callingCode: "230" },
  231: { flag: lr, name: "Liberia", alpha2Code: "LR", callingCode: "231" },
  232: { flag: sl, name: "Sierra Leone", alpha2Code: "SL", callingCode: "232" },
  233: { flag: gh, name: "Ghana", alpha2Code: "GH", callingCode: "233" },
  234: { flag: ng, name: "Nigeria", alpha2Code: "NG", callingCode: "234" },
  235: { flag: td, name: "Chad", alpha2Code: "TD", callingCode: "235" },
  236: { flag: cf, name: "Central African Republic", alpha2Code: "CF", callingCode: "236" },
  237: { flag: cm, name: "Cameroon", alpha2Code: "CM", callingCode: "237" },
  238: { flag: cv, name: "Cabo Verde", alpha2Code: "CV", callingCode: "238" },
  239: { flag: st, name: "Sao Tome and Principe", alpha2Code: "ST", callingCode: "239" },
  240: { flag: gq, name: "Equatorial Guinea", alpha2Code: "GQ", callingCode: "240" },
  241: { flag: ga, name: "Gabon", alpha2Code: "GA", callingCode: "241" },
  242: { flag: cg, name: "Congo", alpha2Code: "CG", callingCode: "242" },
  243: { flag: cd, name: "Congo (Democratic Republic of the)", alpha2Code: "CD", callingCode: "243" },
  244: { flag: ao, name: "Angola", alpha2Code: "AO", callingCode: "244" },
  245: { flag: gw, name: "Guinea-Bissau", alpha2Code: "GW", callingCode: "245" },
  246: { flag: io, name: "British Indian Ocean Territory", alpha2Code: "IO", callingCode: "246" },
  248: { flag: sc, name: "Seychelles", alpha2Code: "SC", callingCode: "248" },
  249: { flag: sd, name: "Sudan", alpha2Code: "SD", callingCode: "249" },
  250: { flag: rw, name: "Rwanda", alpha2Code: "RW", callingCode: "250" },
  251: { flag: et, name: "Ethiopia", alpha2Code: "ET", callingCode: "251" },
  252: { flag: so, name: "Somalia", alpha2Code: "SO", callingCode: "252" },
  253: { flag: dj, name: "Djibouti", alpha2Code: "DJ", callingCode: "253" },
  254: { flag: ke, name: "Kenya", alpha2Code: "KE", callingCode: "254" },
  255: { flag: tz, name: "Tanzania, United Republic of", alpha2Code: "TZ", callingCode: "255" },
  256: { flag: ug, name: "Uganda", alpha2Code: "UG", callingCode: "256" },
  257: { flag: bi, name: "Burundi", alpha2Code: "BI", callingCode: "257" },
  258: { flag: mz, name: "Mozambique", alpha2Code: "MZ", callingCode: "258" },
  260: { flag: zm, name: "Zambia", alpha2Code: "ZM", callingCode: "260" },
  261: { flag: mg, name: "Madagascar", alpha2Code: "MG", callingCode: "261" },
  263: { flag: zw, name: "Zimbabwe", alpha2Code: "ZW", callingCode: "263" },
  264: { flag: na, name: "Namibia", alpha2Code: "NA", callingCode: "264" },
  265: { flag: mw, name: "Malawi", alpha2Code: "MW", callingCode: "265" },
  266: { flag: ls, name: "Lesotho", alpha2Code: "LS", callingCode: "266" },
  267: { flag: bw, name: "Botswana", alpha2Code: "BW", callingCode: "267" },
  268: { flag: sz, name: "Swaziland", alpha2Code: "SZ", callingCode: "268" },
  269: { flag: km, name: "Comoros", alpha2Code: "KM", callingCode: "269" },
  291: { flag: er, name: "Eritrea", alpha2Code: "ER", callingCode: "291" },
  297: { flag: aw, name: "Aruba", alpha2Code: "AW", callingCode: "297" },
  298: { flag: fo, name: "Faroe Islands", alpha2Code: "FO", callingCode: "298" },
  299: { flag: gl, name: "Greenland", alpha2Code: "GL", callingCode: "299" },
  350: { flag: gi, name: "Gibraltar", alpha2Code: "GI", callingCode: "350" },
  351: { flag: pt, name: "Portugal", alpha2Code: "PT", callingCode: "351" },
  352: { flag: lu, name: "Luxembourg", alpha2Code: "LU", callingCode: "352" },
  353: { flag: ie, name: "Ireland", alpha2Code: "IE", callingCode: "353" },
  354: { flag: is, name: "Iceland", alpha2Code: "IS", callingCode: "354" },
  355: { flag: al, name: "Albania", alpha2Code: "AL", callingCode: "355" },
  356: { flag: mt, name: "Malta", alpha2Code: "MT", callingCode: "356" },
  357: { flag: cy, name: "Cyprus", alpha2Code: "CY", callingCode: "357" },
  358: { flag: fi, name: "Finland", alpha2Code: "FI", callingCode: "358" },
  359: { flag: bg, name: "Bulgaria", alpha2Code: "BG", callingCode: "359" },
  370: { flag: lt, name: "Lithuania", alpha2Code: "LT", callingCode: "370" },
  371: { flag: lv, name: "Latvia", alpha2Code: "LV", callingCode: "371" },
  372: { flag: ee, name: "Estonia", alpha2Code: "EE", callingCode: "372" },
  373: { flag: md, name: "Moldova (Republic of)", alpha2Code: "MD", callingCode: "373" },
  374: { flag: am, name: "Armenia", alpha2Code: "AM", callingCode: "374" },
  375: { flag: by, name: "Belarus", alpha2Code: "BY", callingCode: "375" },
  376: { flag: ad, name: "Andorra", alpha2Code: "AD", callingCode: "376" },
  377: { flag: mc, name: "Monaco", alpha2Code: "MC", callingCode: "377" },
  378: { flag: sm, name: "San Marino", alpha2Code: "SM", callingCode: "378" },
  380: { flag: ua, name: "Ukraine", alpha2Code: "UA", callingCode: "380" },
  381: { flag: rs, name: "Serbia", alpha2Code: "RS", callingCode: "381" },
  382: { flag: me, name: "Montenegro", alpha2Code: "ME", callingCode: "382" },
  385: { flag: hr, name: "Croatia", alpha2Code: "HR", callingCode: "385" },
  386: { flag: si, name: "Slovenia", alpha2Code: "SI", callingCode: "386" },
  387: { flag: ba, name: "Bosnia and Herzegovina", alpha2Code: "BA", callingCode: "387" },
  389: { flag: mk, name: "Macedonia (the former Yugoslav Republic of)", alpha2Code: "MK", callingCode: "389" },
  420: { flag: cz, name: "Czech Republic", alpha2Code: "CZ", callingCode: "420" },
  421: { flag: sk, name: "Slovakia", alpha2Code: "SK", callingCode: "421" },
  423: { flag: li, name: "Liechtenstein", alpha2Code: "LI", callingCode: "423" },
  500: { flag: fk, name: "Falkland Islands (Malvinas)", alpha2Code: "FK", callingCode: "500" },
  501: { flag: bz, name: "Belize", alpha2Code: "BZ", callingCode: "501" },
  502: { flag: gt, name: "Guatemala", alpha2Code: "GT", callingCode: "502" },
  503: { flag: sv, name: "El Salvador", alpha2Code: "SV", callingCode: "503" },
  504: { flag: hn, name: "Honduras", alpha2Code: "HN", callingCode: "504" },
  505: { flag: ni, name: "Nicaragua", alpha2Code: "NI", callingCode: "505" },
  506: { flag: cr, name: "Costa Rica", alpha2Code: "CR", callingCode: "506" },
  507: { flag: pa, name: "Panama", alpha2Code: "PA", callingCode: "507" },
  509: { flag: ht, name: "Haiti", alpha2Code: "HT", callingCode: "509" },
  591: { flag: bo, name: "Bolivia (Plurinational State of)", alpha2Code: "BO", callingCode: "591" },
  593: { flag: ec, name: "Ecuador", alpha2Code: "EC", callingCode: "593" },
  595: { flag: py, name: "Paraguay", alpha2Code: "PY", callingCode: "595" },
  596: { flag: mq, name: "Martinique", alpha2Code: "MQ", callingCode: "596" },
  597: { flag: sr, name: "Suriname", alpha2Code: "SR", callingCode: "597" },
  598: { flag: uy, name: "Uruguay", alpha2Code: "UY", callingCode: "598" },
  599: { flag: cw, name: "Curaçao", alpha2Code: "CW", callingCode: "599" },
  672: { flag: nf, name: "Norfolk Island", alpha2Code: "NF", callingCode: "672" },
  674: { flag: nr, name: "Nauru", alpha2Code: "NR", callingCode: "674" },
  675: { flag: pg, name: "Papua New Guinea", alpha2Code: "PG", callingCode: "675" },
  676: { flag: to, name: "Tonga", alpha2Code: "TO", callingCode: "676" },
  677: { flag: sb, name: "Solomon Islands", alpha2Code: "SB", callingCode: "677" },
  678: { flag: vu, name: "Vanuatu", alpha2Code: "VU", callingCode: "678" },
  679: { flag: fj, name: "Fiji", alpha2Code: "FJ", callingCode: "679" },
  680: { flag: pw, name: "Palau", alpha2Code: "PW", callingCode: "680" },
  682: { flag: ck, name: "Cook Islands", alpha2Code: "CK", callingCode: "682" },
  683: { flag: nu, name: "Niue", alpha2Code: "NU", callingCode: "683" },
  685: { flag: ws, name: "Samoa", alpha2Code: "WS", callingCode: "685" },
  686: { flag: ki, name: "Kiribati", alpha2Code: "KI", callingCode: "686" },
  688: { flag: tv, name: "Tuvalu", alpha2Code: "TV", callingCode: "688" },
  689: { flag: pf, name: "French Polynesia", alpha2Code: "PF", callingCode: "689" },
  690: { flag: tk, name: "Tokelau", alpha2Code: "TK", callingCode: "690" },
  691: { flag: fm, name: "Micronesia (Federated States of)", alpha2Code: "FM", callingCode: "691" },
  692: { flag: mh, name: "Marshall Islands", alpha2Code: "MH", callingCode: "692" },
  850: { flag: kp, name: "Korea (Democratic People's Republic of)", alpha2Code: "KP", callingCode: "850" },
  852: { flag: hk, name: "Hong Kong", alpha2Code: "HK", callingCode: "852" },
  853: { flag: mo, name: "Macao", alpha2Code: "MO", callingCode: "853" },
  855: { flag: kh, name: "Cambodia", alpha2Code: "KH", callingCode: "855" },
  856: { flag: la, name: "Lao People's Democratic Republic", alpha2Code: "LA", callingCode: "856" },
  880: { flag: bd, name: "Bangladesh", alpha2Code: "BD", callingCode: "880" },
  886: { flag: tw, name: "Taiwan", alpha2Code: "TW", callingCode: "886" },
  960: { flag: mv, name: "Maldives", alpha2Code: "MV", callingCode: "960" },
  961: { flag: lb, name: "Lebanon", alpha2Code: "LB", callingCode: "961" },
  962: { flag: jo, name: "Jordan", alpha2Code: "JO", callingCode: "962" },
  963: { flag: sy, name: "Syrian Arab Republic", alpha2Code: "SY", callingCode: "963" },
  964: { flag: iq, name: "Iraq", alpha2Code: "IQ", callingCode: "964" },
  965: { flag: kw, name: "Kuwait", alpha2Code: "KW", callingCode: "965" },
  966: { flag: sa, name: "Saudi Arabia", alpha2Code: "SA", callingCode: "966" },
  967: { flag: ye, name: "Yemen", alpha2Code: "YE", callingCode: "967" },
  968: { flag: om, name: "Oman", alpha2Code: "OM", callingCode: "968" },
  970: { flag: ps, name: "Palestine, State of", alpha2Code: "PS", callingCode: "970" },
  971: { flag: ae, name: "United Arab Emirates", alpha2Code: "AE", callingCode: "971" },
  972: { flag: il, name: "Israel", alpha2Code: "IL", callingCode: "972" },
  973: { flag: bh, name: "Bahrain", alpha2Code: "BH", callingCode: "973" },
  974: { flag: qa, name: "Qatar", alpha2Code: "QA", callingCode: "974" },
  975: { flag: bt, name: "Bhutan", alpha2Code: "BT", callingCode: "975" },
  976: { flag: mn, name: "Mongolia", alpha2Code: "MN", callingCode: "976" },
  977: { flag: np, name: "Nepal", alpha2Code: "NP", callingCode: "977" },
  992: { flag: tj, name: "Tajikistan", alpha2Code: "TJ", callingCode: "992" },
  993: { flag: tm, name: "Turkmenistan", alpha2Code: "TM", callingCode: "993" },
  994: { flag: az, name: "Azerbaijan", alpha2Code: "AZ", callingCode: "994" },
  995: { flag: ge, name: "Georgia", alpha2Code: "GE", callingCode: "995" },
  996: { flag: kg, name: "Kyrgyzstan", alpha2Code: "KG", callingCode: "996" },
  998: { flag: uz, name: "Uzbekistan", alpha2Code: "UZ", callingCode: "998" },
  1242: { flag: bs, name: "Bahamas", alpha2Code: "BS", callingCode: "1242" },
  1246: { flag: bb, name: "Barbados", alpha2Code: "BB", callingCode: "1246" },
  1264: { flag: ai, name: "Anguilla", alpha2Code: "AI", callingCode: "1264" },
  1268: { flag: ag, name: "Antigua and Barbuda", alpha2Code: "AG", callingCode: "1268" },
  1340: { flag: vi, name: "Virgin Islands (U.S.)", alpha2Code: "VI", callingCode: "1340" },
  1345: { flag: ky, name: "Cayman Islands", alpha2Code: "KY", callingCode: "1345" },
  1441: { flag: bm, name: "Bermuda", alpha2Code: "BM", callingCode: "1441" },
  1473: { flag: gd, name: "Grenada", alpha2Code: "GD", callingCode: "1473" },
  1649: { flag: tc, name: "Turks and Caicos Islands", alpha2Code: "TC", callingCode: "1649" },
  1664: { flag: ms, name: "Montserrat", alpha2Code: "MS", callingCode: "1664" },
  1670: { flag: mp, name: "Northern Mariana Islands", alpha2Code: "MP", callingCode: "1670" },
  1671: { flag: gu, name: "Guam", alpha2Code: "GU", callingCode: "1671" },
  1684: { flag: as, name: "American Samoa", alpha2Code: "AS", callingCode: "1684" },
  1721: { flag: sx, name: "Sint Maarten (Dutch part)", alpha2Code: "SX", callingCode: "1721" },
  1758: { flag: lc, name: "Saint Lucia", alpha2Code: "LC", callingCode: "1758" },
  1767: { flag: dm, name: "Dominica", alpha2Code: "DM", callingCode: "1767" },
  1787: { flag: pr, name: "Puerto Rico", alpha2Code: "PR", callingCode: "1787" },
  1809: { flag: dof, name: "Dominican Republic", alpha2Code: "DO", callingCode: "1" },
  1868: { flag: tt, name: "Trinidad and Tobago", alpha2Code: "TT", callingCode: "1868" },
  1869: { flag: kn, name: "Saint Kitts and Nevis", alpha2Code: "KN", callingCode: "1869" },
  1876: { flag: jm, name: "Jamaica", alpha2Code: "JM", callingCode: "1876" },
  592: { flag: gy, name: "Guyane", alpha2Code: "GY", callingCode: "592" },
  594: { flag: gf, name: "Franch Guyane", alpha2Code: "GF", callingCode: "594" },
  262: { flag: re, name: "Reunion Island ", alpha2Code: "RE", callingCode: "262" },
  590: { flag: gp, name: "Guadeloupe", alpha2Code: "GP", callingCode: "590" },
};
