export function loadMagicWriteScriptAgenteMW() {
  window.$mw = {
    companyID: "co_01H0WS4AKXQPCMZNT6AV1JA7ZY",
    groupID: "ag_01H4R9MED3KMHJW4YK0DYYR0FP",
  };

  loadMagicWriteScript();
}

function loadMagicWriteScript() {
  const script = document.getElementById("scriptAgenteMW") ?? document.createElement("script");
  script.src = "https://agents.magicwrite.ai/loader.js";
  script.id = "scriptAgenteMW";
  script.async = true;
  document.head.appendChild(script);

  script.onload = function() {
    const widgetFrame = document.getElementsByName("mw-widget-frame")[0];
    if (widgetFrame) {
      widgetFrame.style.bottom = "0px";
    }
  };
}
