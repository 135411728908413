import { ACCESS_LEVEL } from "../constantes";

export default function hasPermission(user, claims, operator = "AND") {
  const { permissoes: permissions } = user;

  function verifyClaims(claim) {
    /** encontra a permissão do usuário */
    const permission = permissions.find(p => p.funcionalidade === claim);

    /** se não encontra permissão, ele não pode acessar */
    if (!permission) return false;

    const { nivelAcesso: accessLevel } = permission;

    if (accessLevel === ACCESS_LEVEL.NONE) return false;

    return true;
  }

  if (operator === "AND") return claims.every(verifyClaims);
  else if (operator === "OR") return claims.some(verifyClaims);

  throw new Error("Operador inválido");
}
