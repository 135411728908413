import { datadogRum } from "@datadog/browser-rum";
import env from "../../../../environment";
import { appInsights } from "./ApplicationInsightsService";
import localStorageManager from "../../storage";
import { HABILITAR_DATADOG } from "../../storage/constantesStorage";

export const AddLogError = exception => {
  if (env.HABILITA_APP_INSIGHT) {
    appInsights.trackException(exception);
  }

  if (localStorageManager.getItem(HABILITAR_DATADOG)) {
    datadogRum.addError(exception);
  }
};
