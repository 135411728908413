export const JWT_TOKEN = "jwt_token";
export const REFRESH_TOKEN = "refresh_token";
export const INFOS_USUARIO = "infos_usuario";
export const RECAPTCHA = "recaptcha";
export const ENV = "env";
export const BUSCA_AVANCADA = "BuscaAvancadaSalva";
export const TIPO_PLANO = "tipoPlano";
export const RD_SUMMIT_STICKBAR = "dimmissRDSummitStickbar";
export const TIMELINE_ALREADY_REFRESHED = "TIMELINE_ALREADY_REFRESHED";
export const DASHBOARD_PERIODO_PADRAO = "dashboardPeriodoPadrao";
export const ATUALIZAR_DASHBOARD = "atualizar_dashboard";
export const HABILITAR_DATADOG = "HABILITAR_DATADOG";
export const ADICIONAR_SERVICE_WORKER = "ADICIONAR_SERVICE_WORKER";
