import { intersection } from "lodash";

import { ACCESS_LEVEL } from "../constantes";

export default function hasPermission(
  user,
  claims,
  leads,
  operator = "AND",
  checkIndividualOrGroupLevelWithoutleads = false
) {
  const { permissoes: permissions } = user;
  const userId = user.id || user.userId;

  function verifyClaims(claim) {
    /** se não encontra permissões, ele não pode acessar porque nao esta logado */
    if (!permissions) return false;
    /** encontra a permissão do usuário */
    const permission = permissions.find(p => p.funcionalidade === claim);

    /** se não encontra permissão, ele não pode acessar */
    if (!permission) return false;

    const { nivelAcesso: accessLevel, grupos: userGroups } = permission;

    /** se a permissão for nenhuma, nem olha nos leads, já não pode acessar */
    if (accessLevel === ACCESS_LEVEL.NONE) return false;

    /** se a permissão for completa, nem olha nos leads, ele já pode acessar */
    if (accessLevel === ACCESS_LEVEL.COMPLETE) return true;

    /** se a flag checkIndividualOrGroupLevelWithoutleads for true e a permissão for individual ou grupo, nem olha nos leads, ele já pode acessar */
    if (
      checkIndividualOrGroupLevelWithoutleads &&
      (accessLevel === ACCESS_LEVEL.INDIVIDUAL || accessLevel === ACCESS_LEVEL.GROUP)
    )
      return true;

    /** permissões do tipo Grupo e Individual só podem ser verificadas com uma lista de leads */
    if (!leads.length) return false;

    /** verifica que tem a permissão pra cada lead na lista */
    return leads.every(lead => {
      const { grupos: groups, preVendedorId: owner, vendedorId: seller, usuarioOriginalId: originalOwner } = {
        grupos: [],
        ...lead,
      };

      return {
        /** se a permissão for nível de grupo, deve ter acesso a pelo menos um dos grupos do lead */
        [ACCESS_LEVEL.GROUP]: () =>
          !!intersection(userGroups, groups).length /** se a permissão for nível individual, deve ser o dono do lead */,
        [ACCESS_LEVEL.INDIVIDUAL]: () => userId === owner || userId === seller || userId === originalOwner,
      }[accessLevel]();
    });
  }

  if (operator === "AND") return claims.every(verifyClaims);
  else if (operator === "OR") return claims.some(verifyClaims);

  throw new Error("Operador inválido");
}
