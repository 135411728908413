import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import Axios from "axios";
import env from "../../../../environment";

export const isDatadogHabilitado = async () => {
  const baseUrl = env.REACT_APP_BASE_URL;
  const response = await Axios.get(`${baseUrl}/api/pipeline/appsetting/LogsFrontendDataDogEstaoHabilitado`);
  return response.data;
};

export const AddLogDataDog = (message, messageContext, status, error = null) => {
  const config = datadogLogs.getInitConfiguration();
  if (config === undefined) return;
  datadogLogs.logger.log(message, messageContext, status, error);
};

export const addPropertyContextView = async (context, contextValue) => {
  datadogRum.setViewContextProperty(context, contextValue);
};

export const loadDatadogBrowserRum = () => {
  const config = datadogRum.getInitConfiguration();
  if (config !== undefined) return;

  const DATA_SESSION_SAMPLE_RATE = env.DATA_SESSION_SAMPLE_RATE ? Number(env.DATA_SESSION_SAMPLE_RATE) : 1;
  const DATA_SESSION_REPLAY_SAMPLE_RATE = env.DATA_SESSION_REPLAY_SAMPLE_RATE
    ? Number(env.DATA_SESSION_REPLAY_SAMPLE_RATE)
    : 1;
  const DATA_SESSION_SAMPLE_RATE_LIB_LOGS = env.DATA_SESSION_SAMPLE_RATE_LIB_LOGS
    ? Number(env.DATA_SESSION_SAMPLE_RATE_LIB_LOGS)
    : 0;

  datadogRum.init({
    applicationId: env.DATA_DOG_APPLICATION_ID,
    clientToken: env.DATA_DOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "spotter-front",
    env: env.NODE_ENV,
    sessionSampleRate: DATA_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: DATA_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogLogs.init({
    clientToken: env.DATA_DOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: false,
    sessionSampleRate: DATA_SESSION_SAMPLE_RATE_LIB_LOGS,
    env: env.NODE_ENV,
    service: "spotter-front",
    sendLogsAfterSessionExpiration: false,
  });
};

export const addUserInfosDatadogBrowserRum = (userID, email, nome, empresaClienteID) => {
  datadogRum.setUser({ id: userID, email, name: nome, EmpresaClienteID: empresaClienteID, UserID: userID });
};
