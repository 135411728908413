import { defineMessage } from "react-intl";
import { ACCESS_LEVEL } from "../constantes";

/**
 * módulo criado pra facilitar a lista de permissões existentes na aplicação
 * idealmente deve espelhar o enum eFuncionalidade do backend, adicionando informações de exibição
 * idealmente deve ser usado somente através dos exports em index.js
 */

export const GRUPOS = {
  LEAD: "Lead",
  FILTRO: "Filtro",
  TELA: "Telas e módulos",
  TELEFONIA: "Telefonia",
  ORGANIZACAO: "Organização",
};

export const INTL_GRUPOS = {
  [GRUPOS.LEAD]: defineMessage({
    defaultMessage: "Lead",
  }),
  [GRUPOS.FILTRO]: defineMessage({
    defaultMessage: "Filtro",
  }),
  [GRUPOS.TELA]: defineMessage({
    defaultMessage: "Telas e módulos",
  }),
  [GRUPOS.TELEFONIA]: defineMessage({
    defaultMessage: "Telefonia",
  }),
  [GRUPOS.ORGANIZACAO]: defineMessage({ defaultMessage: "Organização" }),
};

/**
 * Usado para definir se a permissão é relevante ao tipo de usuário,
 * eg, a permissão X não deve ser mostrada para o accessGroup GERENTE:
 * { id: "X",..., accessGroups: [ACCESS_GROUPS.PV, ACCESS_GROUPS.VENDEDOR] }
 */
const ACCESS_GROUPS = { GERENTE: "gerente", PV: "prevendedor", VENDEDOR: "vendedor" };

const tooltipPermissaoEtapa = defineMessage({
  defaultMessage:
    "Para realizar esta ação, também é necessário que o usuário possua permissão para interagir na etapa que o lead estiver.",
});

export const ACCESS_LEVEL_LABELS = {
  [ACCESS_LEVEL.NONE]: defineMessage({ defaultMessage: "Nenhum" }),
  [ACCESS_LEVEL.INDIVIDUAL]: defineMessage({ defaultMessage: "Individual" }),
  [ACCESS_LEVEL.GROUP]: defineMessage({ defaultMessage: "Grupo" }),
  [ACCESS_LEVEL.COMPLETE]: defineMessage({ defaultMessage: "Completo" }),
};

const PERMISSION_OPTIONS = {
  BINARIA: [ACCESS_LEVEL.NONE, ACCESS_LEVEL.COMPLETE],
  ALL: [ACCESS_LEVEL.NONE, ACCESS_LEVEL.INDIVIDUAL, ACCESS_LEVEL.GROUP, ACCESS_LEVEL.COMPLETE],
  PARCIAL_1: [ACCESS_LEVEL.NONE, ACCESS_LEVEL.INDIVIDUAL, ACCESS_LEVEL.COMPLETE],
};

/**
 * Resolve como calcular as opções de configuração para uma permissão.
 * As permissões possuem duas formas de determinar quais opções aceitam:
 *    - com a flag `binaria`;
 *    - ou com uma função `getOptions`, a qual pode ser ser passado o accessGroup para determinar as opções.
 * @param {object} permission
 * @param {string} accessGroup One of ACCESS_GROUPS
 * @returns {array} One of PERMISSION_OPTIONS
 */
export const getPermissionOptions = (permission, accessGroup) => {
  if (typeof permission.getOptions === "function") {
    return permission.getOptions(accessGroup);
  }

  return permission.binaria ? PERMISSION_OPTIONS.BINARIA : PERMISSION_OPTIONS.ALL;
};

export default {
  // permissões do lead
  ALTERAR_DOR_PRODUTO: {
    id: 1,
    descricao: defineMessage({ defaultMessage: "Editar Dor e Produto (Lead/Oportunidade)" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  ALTERAR_CAMPOS_PERSONALIZADOS: {
    id: 14,
    descricao: defineMessage({ defaultMessage: "Editar Campos Personalizados (Lead/Oportunidade)" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  ALTERAR_EMPRESA: {
    id: 15,
    descricao: defineMessage({ defaultMessage: "Editar Detalhes da Empresa (Lead/Oportunidade)" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  ALTERAR_CONTATOS: {
    id: 16,
    descricao: defineMessage({ defaultMessage: "Editar Contatos (Lead/Oportunidade)" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  ALTERAR_OBSERVACOES: {
    id: 17,
    descricao: defineMessage({ defaultMessage: "Editar Observações (Lead/Oportunidade)" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  APAGAR_LEAD: {
    id: 2,
    descricao: defineMessage({ defaultMessage: "Excluir" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  // CONHECER: { id: 3, descricao: "CONHECER", binaria: false, grupo: GRUPOS.LEAD },
  DESCARTAR: {
    id: 4,
    descricao: defineMessage({ defaultMessage: "Descartar" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  EXPORTAR: { id: 5, descricao: defineMessage({ defaultMessage: "Exportar" }), binaria: false, grupo: GRUPOS.LEAD },
  MARCAR_VENDIDO: {
    id: 6,
    descricao: defineMessage({ defaultMessage: "Marcar como Vendido" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  RECUPERAR: {
    id: 7,
    descricao: defineMessage({ defaultMessage: "Recuperar" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  TRANSFERIR: {
    id: 8,
    descricao: defineMessage({ defaultMessage: "Transferir" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  VISUALIZAR_DETALHE: {
    id: 9,
    descricao: defineMessage({ defaultMessage: "Visualizar Detalhes do Lead/Oportunidade" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  VISUALIZAR_LEAD: {
    id: 10,
    descricao: defineMessage({ defaultMessage: "Visualizar nas listas" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: defineMessage({
      defaultMessage:
        "Determina se o lead aparecerá na Lista de Leads, nas consultas de Leads Semelhantes ao editar os detalhes ou cadastrar e na Busca de Leads",
    }),
  },
  VISUALIZAR_BASE: {
    id: 56,
    descricao: defineMessage({ defaultMessage: "Visualizar no Funil" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: defineMessage({
      defaultMessage: "Determina se o lead aparecerá na Base de Leads",
    }),
  },
  COMENTAR: { id: 11, descricao: defineMessage({ defaultMessage: "Comentar" }), binaria: false, grupo: GRUPOS.LEAD },
  EFETUAR_LIGACOES: {
    id: 12,
    descricao: defineMessage({ defaultMessage: "Ligar" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  REAGENDAR: {
    id: 13,
    descricao: defineMessage({ defaultMessage: "Reagendar" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  ENCONTRARCONTATOS: {
    id: 41,
    descricao: defineMessage({ defaultMessage: "Encontrar contatos e validar e-mails" }),
    binaria: true,
    grupo: GRUPOS.LEAD,
  },
  SINALIZAR_REUNIAO_CONFIRMADA: {
    id: 49,
    descricao: defineMessage({ defaultMessage: "Sinalizar reunião confirmada" }),
    binaria: true,
    grupo: GRUPOS.LEAD,
  },
  REINICIAR_CICLO: {
    id: 52,
    descricao: defineMessage({ defaultMessage: "Reiniciar ciclo do lead" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  INSERIR_WORKFLOW_MANUALMENTE: {
    id: 51,
    descricao: defineMessage({ defaultMessage: "Inserir em Workflow manualmente" }),
    binaria: true,
    grupo: GRUPOS.LEAD,
  },
  INSERIR_CADENCIA_MANUALMENTE: {
    id: 53,
    descricao: defineMessage({ defaultMessage: "Inserir/remover em Cadências de E-mail" }),
    binaria: true,
    grupo: GRUPOS.LEAD,
  },
  // CRIARATIVIDADE: { id: 48, descricao: "Criar atividades", binaria: false, grupo: GRUPOS.LEAD },
  // EDITAR: { id: 35, descricao: "EDITAR", binaria: false, grupo: GRUPOS.LEAD },
  // filtro
  PULAR_ETAPAS: {
    id: 60,
    descricao: defineMessage({ defaultMessage: "Pular etapas" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
    tooltip: defineMessage({
      defaultMessage:
        "Para realizar essa operação, é preciso que o pulo entre etapas esteja habilitado nas Configurações Avançadas",
    }),
  },
  APLICAR_FILTRO: {
    id: 33,
    descricao: defineMessage({ defaultMessage: "Realizar Filtro" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
    tooltip: tooltipPermissaoEtapa,
  },
  APLICAR_FEEDBACK: {
    id: 34,
    descricao: defineMessage({ defaultMessage: "Aplicar feedback" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
    tooltip: tooltipPermissaoEtapa,
  },
  REAVALIAR: {
    id: 35,
    descricao: defineMessage({ defaultMessage: "Reavaliar (Filtro)" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
  },
  REAVALIAR_FEEDBACK: {
    id: 36,
    descricao: defineMessage({ defaultMessage: "Reavaliar (Feedback)" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
  },
  VISUALIZAR_RESPOSTAS: {
    id: 37,
    descricao: defineMessage({ defaultMessage: "Visualizar Respostas (Filtro)" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
  },
  VISUALIZAR_RESPOSTAS_FEEDBACK: {
    id: 38,
    descricao: defineMessage({ defaultMessage: "Visualizar Respostas (Feedback)" }),
    binaria: false,
    grupo: GRUPOS.FILTRO,
  },
  // REENVIAR_EMAIL_FEEDBACK: { id: 38, descricao: "REENVIAR_EMAIL_FEEDBACK", binaria: false, grupo: GRUPOS.FILTRO },
  // áreas e módulos
  AGENDA_VENDEDORES: {
    id: 18,
    descricao: defineMessage({ defaultMessage: "Visualizar Agenda (Vendedores)" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  DISTRIBUICAO_LEADS: {
    id: 19,
    descricao: defineMessage({ defaultMessage: "Distribuição de Leads" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  DORES: { id: 20, descricao: defineMessage({ defaultMessage: "Dores" }), binaria: true, grupo: GRUPOS.TELA },
  GRUPO: { id: 21, descricao: defineMessage({ defaultMessage: "Grupos" }), binaria: true, grupo: GRUPOS.TELA },
  MERCADO: { id: 22, descricao: defineMessage({ defaultMessage: "Mercado" }), binaria: true, grupo: GRUPOS.TELA },
  MOTIVO_DESCARTE: {
    id: 23,
    descricao: defineMessage({ defaultMessage: "Motivo de Descarte" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  MOTIVO_REUNIAO_NAO_OCORRIDA: {
    id: 24,
    descricao: defineMessage({ defaultMessage: "Motivo de Reunião não-ocorrida" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  ORIGEMSUBORIGEM: {
    id: 25,
    descricao: defineMessage({ defaultMessage: "Origem e Sub-origem" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  PRODUTO: { id: 26, descricao: defineMessage({ defaultMessage: "Produto" }), binaria: true, grupo: GRUPOS.TELA },
  RELATORIO_DE_DISTRIBUICAO_DE_LEADS: {
    id: 27,
    descricao: defineMessage({ defaultMessage: "Relatório (Distribuição de Leads)" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  RESULTADO_DE_LIGACAO: {
    id: 28,
    descricao: defineMessage({ defaultMessage: "Resultado de ligação" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  PADRAO_CARGOS: {
    id: 54,
    descricao: defineMessage({ defaultMessage: "Cargos" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  DASHBOARDCUSTOM: {
    id: 55,
    descricao: defineMessage({ defaultMessage: "Configurar dashboards" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  BA: { id: 30, descricao: defineMessage({ defaultMessage: "Business Analytics" }), binaria: true, grupo: GRUPOS.TELA },
  CADENCIA_EMAILS: {
    id: 31,
    binaria: true,
    descricao: defineMessage({ defaultMessage: "Cadência de e-mails" }),
    grupo: GRUPOS.TELA,
    getOptions: () => PERMISSION_OPTIONS.PARCIAL_1,
  },
  SEARCHING: { id: 32, descricao: defineMessage({ defaultMessage: "Searching" }), binaria: true, grupo: GRUPOS.TELA },
  SOMATORIOPRODUTOS: {
    id: 42,
    descricao: defineMessage({ defaultMessage: "Somatório de produtos" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  MODULOATIVIDADES: {
    id: 47,
    descricao: defineMessage({ defaultMessage: "Atividades" }),
    binaria: false,
    grupo: GRUPOS.TELA,
  },
  WORKFLOW: {
    id: 50,
    descricao: defineMessage({ defaultMessage: "Workflow" }),
    binaria: true,
    grupo: GRUPOS.TELA,
  },
  // telefonia
  ABRIR_AUDIO_DAS_LIGACOES: {
    id: 39,
    descricao: defineMessage({ defaultMessage: "Abrir áudio (Ligações)" }),
    binaria: false,
    grupo: GRUPOS.TELEFONIA,
  },
  PAINEL_TELEFONIA: {
    id: 44,
    descricao: defineMessage({ defaultMessage: "Acessar Painel de Telefonia" }),
    binaria: true,
    grupo: GRUPOS.TELEFONIA,
    accessGroups: [ACCESS_GROUPS.GERENTE],
  },
  EXTRATO_TELEFONIA: {
    id: 46,
    descricao: defineMessage({ defaultMessage: "Acessar Extrato de Telefonia" }),
    binaria: false,
    grupo: GRUPOS.TELEFONIA,
  },
  TRANSFERIR_CHATS: {
    id: 43,
    descricao: defineMessage({ defaultMessage: "Transferir chats" }),
    binaria: true,
    grupo: GRUPOS.TELEFONIA,
  },
  // Organizacao
  PERMITIR_EXCLUIR_ORGANIZACAO: {
    id: 61,
    descricao: defineMessage({ defaultMessage: "Permitir excluir organização" }),
    binaria: true,
    grupo: GRUPOS.ORGANIZACAO,
    tooltip: defineMessage({
      defaultMessage: "Permissão para excluir organização",
    }),
  },
  TRANSFERIR_VENDEDOR: {
    id: 62,
    descricao: defineMessage({ defaultMessage: "Transferir vendedor" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: tooltipPermissaoEtapa,
  },
  ALTERAR_TIPO_REUNIAO: {
    id: 63,
    descricao: defineMessage({ defaultMessage: "Alterar tipo de reunião" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
  },
  PERMITIR_EXCLUIR_GRAVACAO: {
    id: 64,
    descricao: defineMessage({ defaultMessage: "Permitir excluir gravação" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: defineMessage({
      defaultMessage: "Permissão para excluir gravação da videoconferência",
    }),
  },
  MOVER_LEADS_LOTE: {
    id: 65,
    descricao: defineMessage({ defaultMessage: "Mover leads em lote" }),
    binaria: false,
    grupo: GRUPOS.LEAD,
    tooltip: defineMessage({
      defaultMessage: "Permissão para mover leads em lote entre funis diferentes ou entre etapas do mesmo funil",
    }),
  },
};
