/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/**
 * Helper for cache control
 * @param {string} cacheKey
 * @param {object} initialState
 */
export function CacheState(cacheKey = "", defaultState = {}) {
  function notify() {
    window.dispatchEvent(new Event(cacheKey));
  }

  function setState(overwriteState = {}) {
    try {
      validateCacheKey();
    } catch (err) {
      return null;
    }

    const nextState = {
      ...parseState(getState()),
      ...parseState(overwriteState),
    };

    localStorage.setItem(cacheKey, JSON.stringify(nextState));
    notify();
    return nextState;
  }

  function getState() {
    let state = null;
    try {
      validateCacheKey();
      state = getValidState();
    } catch (err) {
      state = defaultState;
    }
    return state;
  }

  function validateCacheKey() {
    if (!cacheKey || typeof cacheKey !== "string") {
      throw new Error("CacheState: cacheKey deve ser uma string válida.");
    }
  }

  function getValidState() {
    const state = JSON.parse(localStorage.getItem(cacheKey));
    if (state === null || typeof state !== "object") {
      throw new Error("CacheState: estado inválido.");
    }
    return state;
  }

  function clearState() {
    localStorage.removeItem(cacheKey);
    notify();
  }

  function parseState(state) {
    if (!state) {
      state = {};
    }
    if (typeof state === "function") {
      console.error("CacheState: não é possível armazenar funções.");
      return {};
    }
    if (typeof state !== "object" || state.contructor === Array) {
      return {
        value: state,
      };
    }
    return state;
  }

  return {
    set: setState,
    get: getState,
    clear: clearState,
  };
}
