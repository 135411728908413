import React from "react";
import { useIntl, defineMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  IconButton,
  DialogActions,
  Avatar,
} from "@material-ui/core";
import { CloseIcon } from "@material-ui/icons/Close";

import { Flex } from "../../../_common";

function DialogConfirm({
  text = defineMessage({ defaultMessage: "Esta ação não pode ser desfeita." }),
  title = defineMessage({ defaultMessage: "Confirmar?" }),
  acceptLabel = defineMessage({ defaultMessage: "Confirmar" }),
  cancelLabel = defineMessage({ defaultMessage: "Cancelar" }),
  content,
  actions,
  callback,
  onClose,
  closeButton,
  tamanhoMaximo,
  secondCallBack,
  shouldUnmount = true,
  unmount,
  dialogProps = {},
  valuesText = {},
  avatar,
}) {
  const intl = useIntl();
  const handleAction = accepted => {
    if (callback && typeof callback === "function") callback(accepted, unmount);
    if (shouldUnmount) unmount();
  };

  const handleSecondAction = accepted => {
    if (secondCallBack && typeof secondCallBack === "function") {
      secondCallBack(accepted);
      unmount();
    }
    if (shouldUnmount) unmount();
  };

  const handleClose = () => {
    unmount();
  };
  return (
    <Dialog open maxWidth={tamanhoMaximo || ""} fullWidth {...dialogProps}>
      <DialogTitle style={{ paddingTop: 10 }}>
        <Flex justifyContent="space-between">
          <Flex justifyContent="flex-start">{typeof title === "string" ? title : intl.formatMessage(title)} </Flex>
          <Flex justifyContent="flex-end">
            {onClose && (
              <IconButton onClick={handleClose} style={{ paddingLeft: 112 }}>
                <CloseIcon />
              </IconButton>
            )}
            {!onClose &&
              avatar !== undefined && <Avatar src={avatar} style={{ width: 170, height: 33, borderRadius: "0%" }} />}
          </Flex>
        </Flex>
      </DialogTitle>
      {content || (
        <DialogContent>
          <DialogContentText>
            {typeof text === "string" ? (
              text.split("\n").map(line => <div key={line}>{line}</div>)
            ) : (
              <div>{intl.formatMessage(text, valuesText)}</div>
            )}
          </DialogContentText>
        </DialogContent>
      )}
      {actions ? (
        actions(unmount)
      ) : (
        <DialogActions>
          {!!callback && (
            <Button onClick={() => handleAction(false)}>
              {typeof cancelLabel === "string" ? cancelLabel : intl.formatMessage(cancelLabel)}
            </Button>
          )}
          {closeButton ? (
            <Button onClick={() => (secondCallBack ? handleSecondAction(false) : handleAction(true))}>
              {typeof acceptLabel === "string" ? acceptLabel : intl.formatMessage(acceptLabel)}
            </Button>
          ) : (
            <Button color="primary" onClick={() => (secondCallBack ? handleSecondAction(false) : handleAction(true))}>
              {typeof acceptLabel === "string" ? acceptLabel : intl.formatMessage(acceptLabel)}
            </Button>
          )}
          {closeButton && (
            <Button color="primary" onClick={() => handleAction(true)}>
              {intl.formatMessage({ defaultMessage: "Entendi" })}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

DialogConfirm.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.node,
  acceptLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.func,
  callback: PropTypes.func,
  secondCallBack: PropTypes.func,
  onClose: PropTypes.bool,
  closeButton: PropTypes.bool,
  tamanhoMaximo: PropTypes.string,
  shouldUnmount: PropTypes.bool,
  unmount: PropTypes.func.isRequired,
  dialogProps: PropTypes.object,
  valuesText: PropTypes.object,
  avatar: PropTypes.string,
};

export default withStyles({ root: { paddingLeft: 8, paddingRight: 8 } })(DialogConfirm);
