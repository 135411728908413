import moment from "moment";
/**
 * importação da biblioteca necessária para calcular
 * duração de tempo da forma desejada
 * @see https://stackoverflow.com/questions/42983219/how-format-a-time-in-hours-greater-than-24-hours-angularjs/42985372#42985372
 */
// eslint-disable-next-line
import momentDurationFormatSetup from "moment-duration-format";

export const FORMAT_SECONDS = "ss";
export const FORMAT_MINUTES = "mm:ss";
export const FORMAT_HOUR = "hh:mm:ss";

const DEFAULT_FORMAT_CONFIG = { format: FORMAT_MINUTES, placeholder: "--" };

export const formatTimer = (duration, config = DEFAULT_FORMAT_CONFIG) => {
  const normalizedConfig = { ...DEFAULT_FORMAT_CONFIG, ...config };
  const isValid = validateTimerValue(duration);
  if (!isValid) {
    return buildPlaceholder(normalizedConfig);
  }

  return moment.duration(duration, "seconds").format(normalizedConfig.format, { trim: false, useGrouping: false });
};

function validateTimerValue(value) {
  return typeof value === "number" && value >= 0 && value !== Infinity;
}

function buildPlaceholder({ format, placeholder }) {
  switch (format) {
    case FORMAT_SECONDS:
      return placeholder;
    case FORMAT_HOUR:
      return `${placeholder}:${placeholder}:${placeholder}`;
    case FORMAT_MINUTES:
    default:
      return `${placeholder}:${placeholder}`;
  }
}
