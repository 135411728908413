const errorsAuth = {
  EmailOuSenhaIncorretos: "Ops! Login ou senha incorretos. Confira suas informações e tente novamente",
  SenhaObrigatoria: "A senha é obrigatória",
  LoginInvalido: "Login inválido",
  EmpresaEncerrada: "Sua conta está encerrada",
  AcessoBloqueado: "Acesso bloqueado",
  IpBloqueado: "Endereço IP não permitido. Entre em contato com o suporte",
  Bloqueado: "Bloqueado",
  EnviarCodigo: "Enviar código",
  UsuarioBloqueadoOuNaoEncontrado: "Usuário bloqueado ou não existente",
  QuantidadeLoginExcedido: "Seu login foi bloqueado temporariamente. Tente novamente em 5 minutos",
  TresTentativasLogin: "Atenção: você tem 2 tentativas restantes antes do bloqueio",
  ConfirmeNaoRobo: "Confirme que você não é um robô",
  EmailVazio: "Insira um endereço de email",
  EmailFormatoIncorreto: "Inclua um '@' no endereço de email",
  EmailIncompleto: "Insira texto depois de '@'",
  EmailPosicaoIncorreta: "O '.' não está sendo usado ou está em uma posição incorreta",
  HashInvalido: "Hash inválido. Entre em contato com o suporte técnico",
  NaoPossuiSSO: "Esta empresa não possui configuração SSO",
  DescriptografiaFalhou: "Hash inválido: a descriptografia falhou",
  DescriptografiaFalhouNaData: "Hash inválido: não foi possível descriptografar a data",
  EcidIncompatível: "Ecid incompatível. Contate o suporte",
  TempoExpirouLoginSpotterAdm: "Tempo para logar expirado. Realize o processo novamente no SpotterAdm",
  ErroGenerico: "Houve um erro, tente novamente mais tarde!",
};

export default errorsAuth;
