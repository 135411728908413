import { AddLogError } from "../startUpApplication/analyticsProvider/errorAnalyticsService";

export const verifyRemoveNode = (ID, nameFile, nodeChild, nodeParent) => {
  try {
    if (nodeChild.parentNode !== nodeParent) {
      const errorMessage = `ERROR TELA BRANCA - ${ID} 
        | nameFile: ${nameFile} 
        | input.parentNode.nodeName - ${nodeChild.parentNode.nodeName} 
        | input.parentNode.attributes ${nodeChild.parentNode.attributes}`;
      console.error(errorMessage);
      AddLogError({ exception: new Error(errorMessage) });
      document.getElementById(ID).remove();
      return;
    }
    nodeParent.removeChild(nodeChild);
  } catch (error) {
    const errorMessage = `ERROR TELA BRANCA - ${ID} 
      | nameFile: ${nameFile} 
      | input.parentNode.nodeName - ${nodeChild.parentNode.nodeName} 
      | input.parentNode.attributes ${nodeChild.parentNode.attributes} 
      | error ${error}`;
    console.error(errorMessage);
    AddLogError({ exception: new Error(errorMessage) });
  }
};
