/* eslint-disable react/prop-types */
// Eslint está acusando alguns falsos-positivos nessa regra nesse arquivo (no blockToHTML do convertToHTML)

import React from "react";
import ReactDOM from "react-dom/server";
import { CompositeDecorator } from "draft-js";
import MultiDecorator from "draft-js-plugins-editor/lib/Editor/MultiDecorator";
import { convertFromHTML as convertFromHTMLDraftJs, convertToHTML as convertToHTMLDraftJs } from "draft-convert";
import { reduce } from "lodash";
import { getSelectionEntity } from "draftjs-utils";
import { get } from "dot-prop-immutable";

import { convertStyleStringToObject } from "../string/string";
import EditorTextoDecoratorDinamico from "../../components/EditorTextoDecoratorDinamico";
import EditorTextoMedia from "../../components/EditorTextoMedia";

export const defaultEditorStyles = ["BOLD", "ITALIC", "UNDERLINE", "CODE", "STRIKETHROUGH"];

export const blockRendererFn = block => {
  if (block.getType() === "atomic") {
    return {
      component: EditorTextoMedia,
      editable: false,
    };
  }

  return null;
};

export const convertFromHTML = html =>
  convertFromHTMLDraftJs({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === "span" && node.style.fontSize) {
        switch (node.style.fontSize) {
          case "11px":
            return currentStyle.add("FONT_SIZE_11");
          case "14px":
            return currentStyle.add("FONT_SIZE_14");
          case "18px":
            return currentStyle.add("FONT_SIZE_18");
          case "32px":
            return currentStyle.add("FONT_SIZE_32");
          default:
            return currentStyle;
        }
      }
      return currentStyle;
    },
    htmlToBlock: (nodeName, node, lastList, inBlock) => {
      if (
        (nodeName === "figure" && node?.firstChild?.nodeName === "IMG") ||
        (nodeName === "img" && inBlock !== "atomic")
      ) {
        return "atomic";
      }
      return undefined;
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      switch (nodeName) {
        case "img": {
          const removerCharsNaoNumericos = str => str.replace(/[^0-9]/g, "");

          let height;
          if (node.style.height) {
            height = removerCharsNaoNumericos(node.style.height);
          } else if (node.attributes.height) {
            height = node.attributes.height.value;
          }

          let width;
          if (node.style.width) {
            width = removerCharsNaoNumericos(node.style.width);
          } else if (node.attributes.width) {
            width = node.attributes.width.value;
          }

          return createEntity("IMAGE", "IMMUTABLE", {
            attributes: convertAttributesToObject(node.attributes),
            height,
            width,
          });
        }
        case "a": {
          const data = {
            attributes: convertAttributesToObject(node.attributes),
          };

          if (data.attributes.id === "linkDescadastro") {
            return createEntity("LINK_DESCADASTRO_CANDENCIA", "IMMUTABLE", data);
          }

          return createEntity("LINK", "MUTABLE", data);
        }
        default:
          return undefined;
      }
    },
    blockRendererFn,
  })(html);

export const convertToHTML = (contentState, options) =>
  convertToHTMLDraftJs({
    styleToHTML: style => {
      if (Object.keys(styleMap).find(itemStyle => itemStyle === style)) {
        if (styleMap[style].fontSize) {
          return <span style={{ fontSize: styleMap[style].fontSize }} />;
        }

        return <span />;
      }

      return undefined;
    },
    blockToHTML: ({ type, text }) => {
      switch (type) {
        case "atomic":
          return {
            start: "<figure style='margin: 0;'>",
            end: "</figure>",
          };
        case "unstyled":
          if (typeof text === "string" && text.trim() === "") {
            return <br />;
          }
          return <div />;
        default:
          return undefined;
      }
    },
    entityToHTML: entity => {
      const extendedEntityToHTML = get(options, "extends.entityToHTML");
      if (extendedEntityToHTML) {
        const retorno = extendedEntityToHTML(entity);

        if (retorno) {
          return retorno;
        }
      }

      switch (entity.type.toUpperCase()) {
        case "IMAGE":
          return ReactDOM.renderToString(<EditorTextoMedia entity={entity} />);
        case "LINK":
        case "LINK_DESCADASTRO_CANDENCIA":
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          return <a {...entity.data.attributes} />;
        default:
          return undefined;
      }
    },
  })(contentState);

/** Para ser utilizado no editor */
export const decoratorArray = [
  {
    strategy: findLinkEntities,
    component: EditorTextoDecoratorDinamico("a"),
  },
];

/** Para ser utilizado no ao criar novos editorContents */
export const decorators = new MultiDecorator([new CompositeDecorator(decoratorArray)]);

const findEntities = (entityName, contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === entityName;
  }, callback);
};

function findLinkEntities(...args) {
  findEntities("LINK", ...args);
  findEntities("LINK_DESCADASTRO_CANDENCIA", ...args);
}

const convertAttributesToObject = attributes =>
  reduce(
    attributes,
    (acc, attr) => ({
      ...acc,
      [attr.name]: attr.name === "style" ? convertStyleStringToObject(attr.value) : attr.value,
    }),
    {}
  );

export const getActiveButtons = editorState => {
  const entityKey = getSelectionEntity(editorState);
  let link = false;
  if (entityKey) {
    const contentState = editorState.getCurrentContent();
    const entity = contentState.getEntity(entityKey);
    const entityType = entity.getType();

    if (entityType === "LINK") {
      link = true;
    }
  }

  return {
    link,
  };
};

export const getSelectedText = editorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContentBlock = contentState.getBlockForKey(anchorKey);
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  return currentContentBlock.getText().slice(start, end);
};

export const styleMap = {
  FONT_SIZE_11: {
    fontSize: "11px",
  },
  FONT_SIZE_14: {
    fontSize: "14px",
  },
  FONT_SIZE_18: {
    fontSize: "18px",
  },
  FONT_SIZE_32: {
    fontSize: "32px",
  },
};
