import { connect } from "react-redux";
import { setApiInfo, verificaAutenticacao } from "../../store/reducers/emails/emailsActions";

const EmailProvider = ({ jwt, listaVariaveis, canalComunicacaoWebsocket, isPVOrVendedor, verifica }) => {
  if (isPVOrVendedor) {
    const { urlSpotterMailService } = listaVariaveis;

    setApiInfo({
      tokenJwt: jwt,
      UrlSpotterMailService: urlSpotterMailService,
      canalComunicacaoWebsocket,
    });

    verifica();
  }

  return null;
};

const mapState = ({ usuario, variaveisAmbiente }) => ({ ...usuario, ...variaveisAmbiente });
const mapDispatch = dispatch => ({
  verifica: () => dispatch(verificaAutenticacao()),
});

export default connect(
  mapState,
  mapDispatch
)(EmailProvider);
