/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export function initializeGoogleAnalytics(name, empresaClienteId, onBoardingTags, tipoUsuario) {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-XEQQCQWRTF");

  gtag("set", "user_properties", {
    EmailUsuario: name,
    EmpresaClienteId: empresaClienteId,
    OnboardingTags: onBoardingTags,
    TipoUsuario: tipoUsuario,
  });
}
