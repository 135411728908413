import { countries } from "./data";
import { MASKCONFIG, DEFAULT_COUNTRY, FILLMASK } from "./maskConfig";

function Country(ddi, phone) {
  return { ddi: ddi.toString(), phone: phone.toString() };
}

const applyMask = (value, pattern) => {
  let i = 0;
  let j = 0;
  const str = value.toString();
  return [...pattern]
    .reduce((acc, curr) => {
      if (j < str.length) {
        if (curr === "#") j++;
        return acc + curr;
      }
      return acc;
    }, "")
    .replace(/#/g, () => str[i++] || "");
};

export function formatPhoneMask({ ddi = DEFAULT_COUNTRY, phone, mask, showDDI }) {
  const number = String(phone).replace(/\D/g, "");
  const result = applyMask(number, mask || getDefaultPhoneMask(ddi, number));
  if (showDDI) return `+${ddi} ${result}`;
  return result;
}

function getPhoneInfo(phone, ddiMaxLength = 4) {
  let country = countries[phone.substring(0, ddiMaxLength)];
  if (!country) {
    if (ddiMaxLength) return getPhoneInfo(phone, ddiMaxLength - 1);
    country = countries[DEFAULT_COUNTRY];
  }
  return {
    ddi: country.callingCode,
    phone: phone.substring(ddiMaxLength),
    originalPhone: phone,
  };
}

export function extractDDIPhone(phone) {
  let country = null;
  const onlyNumbers = String(phone).replace(/\D/g, "");
  if (onlyNumbers.startsWith("55")) {
    country = new Country(onlyNumbers.substring(0, 2), onlyNumbers.substring(2));
  } else if (onlyNumbers.length < 11) {
    country = new Country("55", onlyNumbers);
  } else if (onlyNumbers.length === 11) {
    if (onlyNumbers.startsWith("1")) {
      if (onlyNumbers.substring(2, 3) === "9") {
        country = new Country("55", onlyNumbers);
      } else {
        country = new Country("1", onlyNumbers.substring(1));
      }
    } else if (onlyNumbers.substring(2, 3) === "9") {
      country = new Country("55", onlyNumbers);
    } else {
      country = getPhoneInfo(onlyNumbers);
    }
  }
  if (!country) country = getPhoneInfo(onlyNumbers);
  country.originalPhone = onlyNumbers;
  return country;
}

export const getDefaultPhoneMask = (ddi, phone) => {
  try {
    const config = MASKCONFIG[ddi];
    if (!config) return MASKCONFIG.default || FILLMASK;

    const phoneLength = phone?.length ?? 0;
    let mask = config[phoneLength];

    if (!mask) {
      Object.keys(config)
        .filter(x => x.includes("x"))
        .map(key => ({ rule: key.replace(/[\D]/gi, phoneLength), key }))
        .forEach(x => {
          // eslint-disable-next-line no-eval
          if (!mask && eval(x.rule)) {
            mask = config[x.key];
          }
        });
      if (!mask) mask = config.default;
    }

    return mask || FILLMASK;
  } catch (e) {
    return FILLMASK;
  }
};
